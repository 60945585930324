import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";

import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";

import DataTable from "react-data-table-component";
import confetti from "canvas-confetti";

import { firebase_app } from "../../data/config";
import moment from "moment";
import { Accordion } from "react-bootstrap";
import {
  Edit,
  Globe,
  HelpCircle,
  MessageSquare,
  Search,
  Settings,
} from "react-feather";

const Transactions = (props) => {
  return (
    <Fragment>
      <Breadcrumb parent="General" title={"FAQ"} />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col lg="12">
            <Row
              className="default-according style-1 faq-accordion"
              id="accordionoc"
            >
              <Col sm="12">
                <Accordion>
                  <div className="faq-title">
                    <h6>{"Start of the internship"}</h6>
                  </div>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="0"
                          aria-expanded={true}
                        >
                          <HelpCircle />
                          {"What will be your role throughout the internship ?"}
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="0">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          Your role will be to run a fundraising campaign
                          through your network, pitching the cause to friends,
                          family, co- workers, relatives, classmates etc. You
                          will be guided about how to raise funds. The funds
                          will be raised through Muskurahat's website with a
                          transparent and systematic process followed throughout
                          the internship.
                        </p>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="1"
                          aria-expanded={true}
                        >
                          <HelpCircle />{" "}
                          {
                            "Will you be provided with any donor data for raising funds ?"
                          }
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="1">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          We won’t be providing you with any leads or contacts
                          for the internship. <br />
                          But don't worry, from our end, you'll be guided from
                          scratch. We will share all the required training
                          modules, resources, and information. You'll learn how
                          to run a successful campaign, pitching, marketing,
                          etc. These will surely help you proceed well and find
                          potential donors.
                        </p>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="2"
                          aria-expanded={true}
                        >
                          <HelpCircle />
                          {
                            "What are the minimum working hours for the internship ?"
                          }
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="2">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          Since it is work from home, the working hours are
                          flexible. 2-3 hours a day, is enough. You can work at
                          your own convenience.
                        </p>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="3"
                          aria-expanded={true}
                        >
                          <HelpCircle />{" "}
                          {
                            "What is the stipend for the internship? Is there a fixed stipend for the internship ?"
                          }
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="3">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          Stipend is performance based. This means you will get
                          20% of the amount you raise.
                        </p>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="4"
                          aria-expanded={true}
                        >
                          <HelpCircle />{" "}
                          {
                            "How will your progress be tracked throughout the internship ?"
                          }
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="4">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          As you proceed with the internship, you will be
                          required to send weekly updates to your fundraising
                          mentor about your progress and further plans.
                        </p>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="5"
                          aria-expanded={true}
                        >
                          <HelpCircle />{" "}
                          {
                            "Can you use other fundraising platforms for your internship?"
                          }
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="5">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          No, you cannot use any other fundraising portals for
                          raising funds for Muskurahat Foundation. Here are a
                          few reasons why: <br />
                          The portal will ask for various documents which can be
                          time consuming. <br /> They will ask for heavy taxes
                          and additional service charges but not guarantee any
                          fixed or additional donors.
                        </p>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="6"
                          aria-expanded={true}
                        >
                          <HelpCircle />{" "}
                          {
                            "What is the eligibility criteria for receiving the Letter Of Recommendation from the President ?"
                          }
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="6">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          Letter of Recommendation is performance based. You are
                          eligible for it if you raise an amount of Rs. 30000
                          and above.
                        </p>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                  <div className="faq-title">
                    <h6>{"About Muskurahat Foundation"}</h6>
                  </div>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="7"
                          aria-expanded={true}
                        >
                          <HelpCircle />{" "}
                          {
                            "Is the Muskurahat foundation registered and a certified non- profit organization ?"
                          }
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="7">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          Yes, Muskurahat Foundation is a government recognized
                          organisation.
                          <br /> Registered as a Trust on 18th August, 2017:
                          <br />
                          <img
                            width="300px"
                            src="https://muskurahat.org.in/wp-content/uploads/2019/06/Society-e1559469463850.jpg"
                            alt="Registered Trust"
                          />
                          <br />
                          Registered as a Society on 18th June, 2016:
                          <br />
                          <img
                            width="300px"
                            src="https://muskurahat.org.in/wp-content/uploads/2019/06/Trust-e1559469419883.jpg"
                            alt="Registered Society"
                          />
                          <br />
                          Registered under Section 12 A on 6th October, 2017:
                          <br />
                          <img
                            width="300px"
                            src="https://muskurahat.org.in/wp-content/uploads/2019/06/12A-e1559469364422.jpg"
                            alt="Registered Section 12A"
                          />
                        </p>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="8"
                          aria-expanded={true}
                        >
                          <HelpCircle />{" "}
                          {"How can I know the legitimacy of the NGO ?"}
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="8">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          The Audit Reports are our financial statements
                          evaluated by an external expert which gives a report
                          of the flow of our funds and their utilisation on an
                          annual basis.
                          <br /> As a certified NGO, we have to mandatorily
                          submit these reports to the government and that’s how
                          they supervise us.
                          <br /> You can go through our last 3 audit reports
                          which are available on our website, link to which is
                          provided below.
                          <br />
                          <a href="https://muskurahat.org.in/financials/">
                            View Financials
                          </a>
                        </p>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="9"
                          aria-expanded={true}
                        >
                          <HelpCircle /> {"What is tax 80G Tax Exemption ?"}
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="9">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          Muskurahat Foundation under Section 80G of the Income
                          Tax Act, provides tax exemption benefits to donors. It
                          makes the donor eligible for 50% tax deduction from
                          his/her annual taxable income.
                        </p>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="10"
                          aria-expanded={true}
                        >
                          <HelpCircle />{" "}
                          {
                            "How do we know that the money donated by the donors is being utilised genuinely ?"
                          }
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="10">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          Any of you who associates with Muskurahat Foundation,
                          even for a limited duration of time, will receive
                          monthly newsletters and annual reports from our end to
                          keep you informed about our ongoing operations and
                          activities. The reports will also give a detailed
                          division and utilisation of our funds throughout the
                          year.
                          <br /> The Foundation has recently designed a donor
                          portal wherein a donor who donates an amount above Rs.
                          100 gets access to the progress report of a child from
                          any one shelter home or orphanages that we are
                          actively engaged with.
                          <br />
                          The donor can track the progress of the child on five
                          different levels:
                          <br />
                        </p>
                        <ul style={{ listStyle: "initial" }}>
                          <li>Daily classroom learning and activity updates</li>
                          <li>Progress in strengthening value system </li>
                          <li>Life skills education </li>
                          <li>Mental well- being report </li>
                          <li>Academic report </li>
                        </ul>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>

                  <div className="faq-title">
                    <h6>{"Technical queries"}</h6>
                  </div>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="11"
                          aria-expanded={true}
                        >
                          <HelpCircle />
                          {
                            "Do I need to share my referral link with all my donors ?"
                          }
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="11">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          Yes, it is important to ensure that the donors use
                          your referral link while donating. This is because
                          when the donor donates using your link, your unique
                          code will automatically apply. Once the payment is
                          successfully processed, the amount will show on your
                          dashboard and it will be counted under your name.
                        </p>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="12"
                          aria-expanded={true}
                        >
                          <HelpCircle />
                          {
                            "How much time will it take for a transaction to show up on my dashboard ?"
                          }
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="12">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          The amount usually appears immediately on your
                          dashboard. But if not, it will appear maximum within
                          12 hours.
                        </p>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="13"
                          aria-expanded={true}
                        >
                          <HelpCircle />
                          {
                            "My reference has made a donation, but it is not showing on the dashboard ?"
                          }
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="13">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          Firstly check with the donor if they have applied your
                          referral code while donating. If they have applied the
                          code, yet the amount does not show, wait for a while.
                          <br />
                          And if they haven’t applied the code, share the
                          donor’s email address or screenshot of the receipt
                          with your fundraising mentor for further assistance.
                        </p>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="14"
                          aria-expanded={true}
                        >
                          <HelpCircle />
                          {
                            "My reference can’t make a donation. Can you help me with it ?"
                          }
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="14">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          If there are any technical issues due to which you are
                          unable to access your dashboard or the donor is unable
                          to process the donation, share your referral link with
                          your fundraising mentor for further assistance.
                        </p>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="15"
                          aria-expanded={true}
                        >
                          <HelpCircle />{" "}
                          {
                            "I can’t login in my dashboard. Can I know how I can access it ?"
                          }
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="15">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          <a href="http://Donate.muskurahat.org.in/user/login">
                            Login Now
                          </a>
                          <br />
                          You can also deactivate/ reactivate your campaign
                          using the same link.
                        </p>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                  <div className="faq-title">
                    <h6>{"Donor related queries"}</h6>
                  </div>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="16"
                          aria-expanded={true}
                        >
                          <HelpCircle />
                          {
                            "I have a few donors staying abroad and they have international cards. How can they donate ?"
                          }
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="16">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          FCRA certificate's only purpose is for accepting
                          foreign donations. Muskurahat Foundation has not been
                          issued the FCRA certification yet. <br />
                          So, as per our present government policy, we cannot
                          directly accept foreign donations. But there is a way.
                          That the donor sends it to you, and you donate on
                          their behalf.
                          <br /> For doing so,
                          <br /> You will have to apply their details while
                          donating. Which is just name, email and number. Once
                          you put their details, they will receive the donation
                          receipt, mentioning their name and amount.
                        </p>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="17"
                          aria-expanded={true}
                        >
                          <HelpCircle />
                          {
                            "A donor wants to donate through cash/cheque. Is it possible to do so ?"
                          }
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="17">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          Yes, there is an offline method of donating. The donor
                          can directly deposit the cash or cheque to our Axis
                          bank account. If needed, details will be sent by your
                          fundraising mentor. <br />
                          Or, the second option is that you collect the amount
                          in cash or the donor sends it to you using any other
                          method, and you make the donation on their behalf.{" "}
                          <br />
                          For doing so,
                          <br />
                          You will have to apply their details while donating.
                          Which is just name email and number. Once you put
                          their details, they will receive the donation receipt,
                          mentioning their name and amount.
                          <br />
                          Muskurahat’s Ac details-
                          <br />
                          Axis bank.
                          <br />
                          Muskurahat Foundation.
                          <br />
                          Acc no. 919010033994943
                          <br />
                          IFSC code. UTIB0001531
                        </p>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="18"
                          aria-expanded={true}
                        >
                          <HelpCircle />
                          {"What is the minimum amount someone can donate ?"}
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="18">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          The minimum donation starts from ₹1.
                        </p>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Accordion.Toggle
                          as={Card.Header}
                          className="btn btn-link collapsed pl-0"
                          eventKey="19"
                          aria-expanded={true}
                        >
                          <HelpCircle />
                          {
                            "My donor wants to make an anonymous donation. How does that work ?"
                          }
                        </Accordion.Toggle>
                      </h5>
                    </CardHeader>
                    <Accordion.Collapse eventKey="19">
                      <CardBody>
                        <p style={{ fontSize: 14 }}>
                          Ensure that the donor uses your referral link for
                          donating any amount. <br />
                          Incase of an anonymous donation, the donor can use
                          your personal details so that once the donation is
                          made, you can share the donation receipt with the
                          donor.
                        </p>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Transactions;
