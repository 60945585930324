import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import ApexCharts from "react-apexcharts";
import Knob from "knob";
import { areaSpaline, apexMixedCharts } from "./chartsData/apex-charts-data";
import { AlertItemWithIconInverse } from "../ui-kits/alert/alertComponent";
import { AreaSpalineChart } from "../../constant";
import { toast } from "react-toastify";

import GridTable from "@nadavshaar/react-grid-table";
import { firebase_app } from "../../data/config";

import moment from "moment";
import { Check, Edit, Save, X } from "react-feather";
import { useParams } from "react-router";
import Loader from "../ui-kits/loader";
import DownloadButton from "../ui-kits/downloadButton";

const Revenue = (props) => {
  const [selectedBatch, setSelectedBatch] = useState("");
  const [activeRange, setActiveRange] = useState("today");
  const [lastVisible, setLastVisible] = useState(null);
  const [rows, setRows] = useState([]);
  let { tag } = useParams();

  useEffect(() => {
    if (tag)
      firebase_app
        .firestore()
        .collection("profiles")
        .where("tags", "array-contains", tag)
        .orderBy("accountCreationTime", "desc")
        .get()
        .then((snapshot) => {
          let fundraisers = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setRows(fundraisers);
        });
  }, [rows, tag]);

  const columns = [
    {
      id: 1,
      field: "name",
      label: "Name",
      sortable: false,
    },
    {
      id: 2,
      field: "email",
      label: "Email",
      sortable: false,
    },
    {
      id: 3,
      field: "whatsappNumber",
      label: "Whatsapp",
      sortable: false,
    },
    {
      id: 4,
      field: "noOfDonors",
      label: "No Of Donors",
      sortable: true,
    },
    {
      id: 5,
      field: "acheivedGoal",
      label: "Amount Raised",
      sortable: true,
    },
    {
      id: 6,
      field: "batch",
      label: "Batch",
      sortable: false,
    },
    {
      id: 7,
      field: "goal",
      label: "Target Amount",
      sortable: false,
    },
    {
      id: 8,
      field: "goal",
      label: "No Of Days Left",
      sortable: true,
      cellRenderer: ({ data }) => {
        const eventdate = moment(data?.expiryTime.toDate());
        const todaysdate = moment();
        const daysLeft = eventdate.diff(todaysdate, "days");
        return daysLeft;
        // return daysLeft > 0 ? daysLeft + " Days Left" : "Campaign Expired";
      },
      // sort: ({ a, b, isAscending }) => {
      //   console.log(a, b, isAscending);
      // },
    },
    {
      id: 9,
      field: "referenceCode",
      label: "Reference Code",
      sortable: false,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Fundraisers" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col xl="12">
            <Card className="earning-card">
              <CardHeader>
                <h5>{"Tag: " + tag} </h5>
              </CardHeader>
              <CardBody>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h6>Total Fundraisers: {rows.length}</h6>
                  <h6>
                    Per intern average: ₹
                    {rows.reduce(
                      (a, b) =>
                        parseInt(a.acheivedGoal || 0) +
                        parseInt(b.acheivedGoal || 0),
                      0
                    ) / rows.length}
                  </h6>
                  <h6>
                    Total Funds Raised: ₹
                    {rows.reduce(
                      (a, b) =>
                        parseInt(a.acheivedGoal || 0) +
                        parseInt(b.acheivedGoal || 0),
                      0
                    )}
                  </h6>
                </div>
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h6>
                    Inactive Fundraisers:{" "}
                    {(rows.filter((a) => parseInt(a.acheivedGoal || 0) < 1000)
                      .length *
                      100) /
                      rows.length}
                    %
                  </h6>
                  <h6>
                    Active Fundraisers:{" "}
                    {(rows.filter((a) => parseInt(a.acheivedGoal || 0) > 1000)
                      .length *
                      100) /
                      rows.length}
                    %
                  </h6>
                </div>
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h6>
                    Bronze :{" "}
                    {(rows.filter((a) => parseInt(a.acheivedGoal || 0) < 5000)
                      .length *
                      100) /
                      rows.length}
                    %
                  </h6>
                  <h6>
                    Silver:{" "}
                    {(rows.filter(
                      (a) =>
                        parseInt(a.acheivedGoal || 0) > 5000 &&
                        parseInt(a.acheivedGoal || 0) < 15000
                    ).length *
                      100) /
                      rows.length}
                    %
                  </h6>
                  <h6>
                    Gold:{" "}
                    {(rows.filter(
                      (a) =>
                        parseInt(a.acheivedGoal || 0) > 15000 &&
                        parseInt(a.acheivedGoal || 0) < 30000
                    ).length *
                      100) /
                      rows.length}
                    %
                  </h6>
                  <h6>
                    Platinum:{" "}
                    {(rows.filter((a) => parseInt(a.acheivedGoal || 0) > 30000)
                      .length *
                      100) /
                      rows.length}
                    %
                  </h6>
                </div>

                <GridTable
                  columns={columns}
                  rows={rows}
                  components={{ Loader, PageSize: DownloadButton }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Revenue;
