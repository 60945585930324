import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import moment from "moment";

import { AreaSpalineChart } from "../../constant";

import GridTable from "@nadavshaar/react-grid-table";
import { firebase_app } from "../../data/config";
import clsx from "clsx";

const TimeSpecific = (props) => {
  const [activeCategory, setActiveCategory] = useState("bronze");
  const [lastVisible, setLastVisible] = useState(null);

  const columns = [
    {
      id: 1,
      field: "name",
      label: "Name",
      sortable: false,
    },
    {
      id: 2,
      field: "email",
      label: "Email Id",
      sortable: false,
    },
    {
      id: 3,
      field: "amount",
      label: "Funds Raised",
      sortable: false,
    },
    {
      id: 4,
      field: "acheivedGoal",
      label: "Total Funds Raised",
      sortable: false,
    },
  ];

  const getRange = () => {
    let fromMoney = 0;
    let toMoney = 5000;

    if (activeCategory === "bronze") {
      fromMoney = 0;
      toMoney = 5000;
    }
    if (activeCategory === "silver") {
      fromMoney = 5000;
      toMoney = 15000;
    }
    if (activeCategory === "gold") {
      fromMoney = 15000;
      toMoney = 30000;
    }
    if (activeCategory === "platinum") {
      fromMoney = 30000;
      toMoney = 9999999999999999;
    }

    const range = [];

    range[0] = fromMoney;
    range[1] = toMoney;
    return range;
  };

  const controller = new AbortController();

  const onRowsReset = (tableManager) => {
    if (tableManager.searchApi.searchText === "") {
      tableManager.rowsApi.setRows([]);
      tableManager.rowsApi.setTotalRows(0);
    } else {
      controller.abort();
    }
  };

  const onRowsRequest = async (requestData, tableManager) => {
    if (requestData.searchText === "") {
      const response = await new Promise((r) => {
        const range = getRange();
        console.log(range);

        const query = lastVisible
          ? firebase_app
              .firestore()
              .collection("profiles")
              .where("acheivedGoal", ">", range[0])
              .where("acheivedGoal", "<=", range[1])
              .orderBy("acheivedGoal", "desc")
              .orderBy("lastFundRaised", "desc")
              .limit(20)
              .startAfter(lastVisible)
          : firebase_app
              .firestore()
              .collection("profiles")
              .where("acheivedGoal", ">", range[0])
              .where("acheivedGoal", "<=", range[1])
              .orderBy("acheivedGoal", "desc")
              .orderBy("lastFundRaised", "desc")
              .limit(20);

        query.get().then((snapshot) => {
          let fundraisers = snapshot.docs.map((doc) => ({
            ...doc.data(),
            amount: doc.data().acheivedGoal,
          }));

          console.log(fundraisers);

          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);

          // setFundraisers(filteredFundraisers);
          return r({
            items: fundraisers,
            totalItems:
              fundraisers.length < 20
                ? tableManager.rowsApi.rows.length + fundraisers.length
                : lastVisible
                ? tableManager.rowsApi.rows.length + 40
                : 40,
          });
        });
      });

      return {
        rows: response.items,
        totalRows: response.totalItems,
      };
    } else {
      const response = await new Promise((r) => {
        const searchedRows = tableManager.searchApi.searchRows(
          tableManager.rowsApi.rows
        );
        return r({
          items: searchedRows,
          totalItems: searchedRows.length,
        });
      });

      return {
        rows: response.items,
        totalRows: response.totalItems,
      };
    }
  };

  const ColumnVisibility = ({ tableManager }) => {
    const onCategoryChange = (category) => {
      setLastVisible(null);
      setActiveCategory(category);
      tableManager.paginationApi.setPage(1);
      tableManager.asyncApi.resetRows();
    };

    const activeClass = (time) =>
      clsx(activeCategory === time ? "active" : null);
    return (
      <div
        style={{
          padding: "10px 20px",
          background: "#fff",
          width: "60%",
        }}
        className="rgt-header-container"
      >
        <Row className="m-0" style={{ width: "100%" }}>
          <Col xl="8" md="8" sm="8" className="col-12 p-0">
            <div className="inner-top-left">
              <ul className="d-flex list-unstyled">
                <li
                  onClick={() => onCategoryChange("bronze")}
                  className={activeClass("bronze")}
                >
                  {"Bronze"}
                </li>
                <li
                  onClick={() => onCategoryChange("silver")}
                  className={activeClass("silver")}
                >
                  {"Silver"}
                </li>
                <li
                  onClick={() => onCategoryChange("gold")}
                  className={activeClass("gold")}
                >
                  {"Gold"}
                </li>
                <li
                  onClick={() => onCategoryChange("platinum")}
                  className={activeClass("platinum")}
                >
                  {"Platinum"}
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const Loader = ({ tableManager }) => "Loading";

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Fundraisers" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col xl="12">
            <Card className="earning-card">
              <CardBody>
                <div className="chart-right">
                  <Row>
                    <Col xl="12">
                      <CardBody className="p-0">
                        <GridTable
                          columns={columns}
                          onRowsRequest={onRowsRequest}
                          components={{ ColumnVisibility, Loader }}
                          pageSizes={[20]}
                          onRowsReset={onRowsReset}
                        />
                      </CardBody>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TimeSpecific;
