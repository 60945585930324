import React, { Fragment, useEffect, useState } from "react";
import Loader from "../layout/loader";
import Taptop from "../layout/tap-top";
import Header from "../layout/header";
import Sidebar from "../layout/sidebar";
import Footer from "../layout/footer";
import ThemeCustomize from "../layout/theme-customizer";
import { ToastContainer } from "react-toastify";
import { withRouter } from "react-router-dom";
import { firebase_app } from "../data/config";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useHistory } from "react-router-dom";
import Tour from "reactour";
import { Button } from "reactstrap";

const App = ({ children }) => {
  let history = useHistory();

  const [profile, setProfile] = useState(null);

  useEffect(() => {
    firebase_app
      .firestore()
      .collection("profiles")
      .doc(localStorage.getItem("userId"))
      .get()
      .then((doc) => {
        setProfile({ id: doc.id, ...doc.data() });
      });
  }, []);


  const [opentour, setOpentour] = useState(true);
  const [tourDone, setTourDone] = useState(localStorage.getItem("tourdone"));
  const steps = [
    {
      selector: ".goal-status",
      content: (
        <div>
          Hello {profile?.name},<br /> This is your Internship Dashbaord!
          <br /> You can track your progress here!!!
        </div>
      ),
    },
    {
      selector: ".reference-code",
      content: (
        <div>
          Your reference code is {profile?.referenceCode}
          <br /> Every donation you crack will be recorded under this referral
          code.
        </div>
      ),
    },
    {
      selector: ".copy-link-btn",
      content: (
        <div>
          Copy your unique link from here and share it with your donors.
        </div>
      ),
    },
    {
      selector: ".copy-link-btn",
      content: (
        <div>
          Every donation made using this link will automatically capture your
          referral code
        </div>
      ),
    },
    {
      selector: ".whatsapp-link",
      content: (
        <div>
          Click on Whatsapp button below to share your personal link along with
          a message to your potential donors.
        </div>
      ),
      action: (node) => {
        if (window.innerWidth <= 991) {
          if (document.querySelector(".sidebar-wrapper"))
            document.querySelector(".sidebar-wrapper").className =
              "sidebar-wrapper close_icon";
        }
      },
    },
    {
      selector: ".transactions-nav",
      position: "bottom",
      content: (
        <div>You can check the record of your donations by clicking here.</div>
      ),
      action: (node) => {
        // set timeout
        setTimeout(() => {
          if (window.innerWidth <= 991) {
            if (document.querySelector(".sidebar-wrapper.close_icon"))
              document.querySelector(".sidebar-wrapper.close_icon").className =
                "sidebar-wrapper";
          }
        }, 1000);
      },
    },
    {
      selector: ".transactions-nav",
      content: (
        <div>
          - The donor will also receive a donation receipt on the spot.
          <br />- Check your inbox for an email sent to you as well.
        </div>
      ),
    },
    {
      selector: ".rewards-nav",
      content: <div>Ace your goals and reap the rewards. CLICK HERE!</div>,
    },

    {
      selector: ".star-reward",
      content: <div>Star Level</div>,
      action: (node) => {
        history.push("/dashboard/rewards");
      },
    },
    {
      selector: ".ninja-reward",
      content: <div>Ninja Level</div>,
    },
    {
      selector: ".wizard-reward",
      content: <div>Wizard Level</div>,
    },
    {
      selector: ".legend-reward",
      content: <div>Legend Level</div>,
    },
  ];
  const closeTour = () => {
    setOpentour(false);
  };

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  return (
    <Fragment>
      <Loader />
      <Taptop />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper sidebar-icon">
          <Sidebar />
          {/* {profile && !tourDone && (
            <Tour
              steps={steps}
              rounded={5}
              isOpen={opentour}
              disableInteraction={true}
              disableKeyboardNavigation={false}
              onRequestClose={closeTour}
              onAfterOpen={disableBody}
              onBeforeClose={enableBody}
              showCloseButton={false}
              closeWithMask={false}
              update={history.location.pathname}
              updateDelay={500}
              // lastStepNextButton={
              //   <Button color="primary" onClick={closeTour}>
              //     Next
              //   </Button>
              // }
            />
          )} */}
          <div className="page-body">{children}</div>
          <Footer />
        </div>
      </div>
      {/* <ThemeCustomize/> */}
      <ToastContainer />
    </Fragment>
  );
};
export default withRouter(App);
