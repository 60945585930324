import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";

import DataTable from "react-data-table-component";
import confetti from "canvas-confetti";

import { firebase_app } from "../../data/config";
import moment from "moment";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import {
  Award,
  Clipboard,
  DollarSign,
  GitPullRequest,
  Instagram,
  Linkedin,
  MapPin,
  Users,
} from "react-feather";
import Tour from "reactour";

const Transactions = (props) => {
  const [opentour, setOpentour] = useState(true);

  const steps = [
    {
      selector: ".star-reward",
      content: <div>Star Level</div>,
    },
    {
      selector: ".ninja-reward",
      content: <div>Ninja Level</div>,
    },
    {
      selector: ".wizard-reward",
      content: <div>Wizard Level</div>,
    },
    {
      selector: ".legend-reward",
      content: <div>Legend Level</div>,
    },
  ];

  const closeTour = () => {
    localStorage.setItem("tourdone", true);
    setOpentour(false);
  };

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  return (
    <Fragment>
      <Breadcrumb parent="General" title={"Rewards"} />
      <Container fluid={true}>
        {/* <Tour
          steps={steps}
          rounded={5}
          isOpen={opentour}
          disableInteraction={true}
          disableKeyboardNavigation={false}
          onRequestClose={closeTour}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
          showCloseButton={false}
          closeWithMask={false}
          lastStepNextButton={
            <Button color="primary" onClick={closeTour}>
              Done!
            </Button>
          }
        /> */}
        <Row>
          <Col sm="12" md="6">
            <Card className="star-reward">
              <CardHeader>
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    // justifyContent: "space-between",
                  }}
                >
                  <h5 style={{ marginRight: "20px" }}>Star</h5>
                  <span style={{ color: "#ea4633" }}>(₹1,000 - ₹5,000)</span>
                </div>
              </CardHeader>
              <CardBody>
                <ul>
                  <li>
                    <DollarSign
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    20% Stipend
                  </li>
                  <li>
                    <Award
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    Certificate of Completion
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" md="6">
            <Card className="ninja-reward">
              <CardHeader>
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    // justifyContent: "space-between",
                  }}
                >
                  <h5 style={{ marginRight: "20px" }}>Ninja</h5>
                  <span style={{ color: "#ea4633" }}>(₹5,000 - ₹15,000)</span>
                </div>
              </CardHeader>
              <CardBody>
                <ul>
                  <li>
                    <DollarSign
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    20% Stipend
                  </li>
                  <li>
                    <Award
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    Certificate of Completion
                  </li>
                  <li>
                    <Linkedin
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    Linkedin recommendation from head of Fundraising
                  </li>
                  <li>
                    <Award
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    Certificate for Crowdfunding Course
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" md="6">
            <Card className="wizard-reward">
              <CardHeader>
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    // justifyContent: "space-between",
                  }}
                >
                  <h5 style={{ marginRight: "20px" }}>Wizard</h5>
                  <span style={{ color: "#ea4633" }}>(₹15,000 - ₹30,000)</span>
                </div>
              </CardHeader>
              <CardBody>
                <ul>
                  <li>
                    <DollarSign
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    20% Stipend
                  </li>
                  <li>
                    <Award
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    Certificate of Completion
                  </li>
                  <li>
                    <Linkedin
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    Linkedin recommendation from COO of Muskurahat
                  </li>
                  <li>
                    <Award
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    Certificate for Crowdfunding Course
                  </li>
                  <li>
                    <Instagram
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    Social Media Shoutout
                  </li>
                  <li>
                    <Users
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    Internship Opportunity
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" md="6">
            <Card className="legend-reward">
              <CardHeader>
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    // justifyContent: "space-between",
                  }}
                >
                  <h5 style={{ marginRight: "20px" }}>Legend</h5>
                  <span style={{ color: "#ea4633" }}>(₹30,000 +)</span>
                </div>
              </CardHeader>
              <CardBody>
                <ul>
                  <li>
                    <DollarSign
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    20% Stipend
                  </li>
                  <li>
                    <Award
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    Certificate of Completion
                  </li>
                  <li>
                    <Linkedin
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    Linkedin recommendation from COO of Muskurahat
                  </li>
                  <li>
                    <Award
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    Certificate for Crowdfunding Course
                  </li>
                  <li>
                    <Instagram
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    Social Media Shoutout
                  </li>
                  <li>
                    <Users
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    Internship Opportunity
                  </li>
                  <li>
                    <Clipboard
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    Letter of Recommendation from president of Muskurahat
                  </li>
                  <li>
                    <MapPin
                      style={{
                        position: "relative",
                        top: 5,
                        marginRight: 10,
                        width: 20,
                      }}
                    />
                    Chance to start Muskurahat chapter in your city
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Transactions;
