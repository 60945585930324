import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { useHistory } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  CardFooter,
  Progress,
} from "reactstrap";

import ApexCharts from "react-apexcharts";
import confetti from "canvas-confetti";

import { firebase_app } from "../../data/config";
import moment from "moment";
import { CornerLeftDown, Star, Copy, Share } from "react-feather";
import { Link } from "react-router-dom";
import * as firebase from "firebase/app";
import AllotedStudents from "./allotedStudents";

const General = (props) => {
  let history = useHistory();

  const [donations, setDonations] = useState([]);
  const [profile, setProfile] = useState(null);
  const [campaignExpiry, setCampaignExpiry] = useState("");
  const [campaignExpired, setCampaignExpired] = useState(false);
  const [expiryDaysLeft, setExpiryDaysLeft] = useState(0);
  const [celebration, setCelebration] = useState("");
  const [celebrate, setCelebrate] = useState(true);
  const [whatsappLink, setWhatsappLink] = useState("");
  const [welcomeContent, setWelcomeContent] = useState("");

  useEffect(() => {
    const batch = localStorage.getItem("batch");
    const isSet = localStorage.getItem("isBatchSet");

    if (!!!isSet && batch) {
      try {
        setTimeout(() => {
          firebase_app
            .firestore()
            .collection("profiles")
            .doc(localStorage.getItem("userId"))
            .update({ batch: batch });

          firebase_app
            .firestore()
            .collection("batches")
            .where("name", "==", batch)
            .get()
            .then((docs) => {
              if (docs.empty) {
                firebase_app
                  .firestore()
                  .collection("batches")
                  .add({ name: batch, timestamp: new Date() });
              }
              localStorage.setItem("isBatchSet", "true");
            });
        }, 5000);
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  useEffect(() => {
    firebase_app
      .firestore()
      .collection("profiles")
      .doc(localStorage.getItem("userId"))
      .get()
      .then((doc) => {
        setProfile({ id: doc.id, ...doc.data() });
        setWhatsappLink(`
        https://api.whatsapp.com/send?text=I%20am%20Volunteering%20with%20Muskurahat%20Foundation%2C%20an%20NGO%20based%20in%20Mumbai.%20%0A%0AProject%20KEYtaab%2C%20an%20initiative%20by%20Muskurahat%20Foundation%20aims%20at%20providing%20quality%20education%20to%20the%20underprivileged%20children%20living%20from%20orphanages%2C%20low%20income%20communities%20and%20rural%20villages%20to%20build%20their%20brighter%20future.%0A%0AApart%20from%20quality%20education%2C%20we%20are%20also%20building%20their%20life%20skills%20like%20critical%20thinking%2C%20communication%20and%20problem%20solving%20among%20many%20others%2C%20and%20strive%20to%20improve%20their%20overall%20mental%20well-being.%20%0A%0ACurrently%2C%20we%20are%20working%20with%20475%2B%20children%20in%2010%20shelter%20homes%2C%203%20community%20centres%20and%205%20villages%20across%20Mumbai%20and%20Ranchi%0A%0ATo%20support%20our%20children%2C%20contribute%20at%20https%3A%2F%2Fdonate.muskurahat.org.in%2F%3Fr%3D${
          doc.data().referenceCode
        }%0A%0ATo%20know%20more%2C%20please%20visit%20www.muskurahat.org.in%0A%0AReferral%20Code%3A%20${
          doc.data().referenceCode
        }%0A%28Please%20keep%20in%20mind%20to%20check%20the%20reference%20code%20while%20donating.%20It%20will%20help%20me%20track%20my%20efforts.%29%0A%0AOur%20collective%20support%20can%20enable%20our%20children%20to%20secure%20their%20future.%0ALooking%20forward%20to%20your%20help%21%E2%9C%A8
        `);
        // setWhatsappLink(
        //   `https://api.whatsapp.com/send?text=Hey!%20%0A%0ARecently%20I%20came%20across%20an%20organization%20working%20to%20educate%20children%20from%20under-resourced%20backgrounds.%20I%20am%20currently%20volunteering%20to%20gather%20support%20for%20the%20cause.%20%0A%0A*Project%20KEYtaab*%2C%20an%20initiative%20by%20Muskurahat%20Foundation%2C%20aims%20at%20providing%20the%20underprivileged%20children%20from%20Shelter%20Homes%2C%20and%20underserved%20communities%20with%20the%20holistic%20development%20that%20they%20lack.%20%0A%0AThrough%20a%20specially%20crafted%20curriculum%2C%20we%20are%20providing%20them%20with%20a%20proper%20*Upbringing*%2C%20helping%20them%20build%20*21st-century%20life%20skills*%20like%20critical%20thinking%20and%20problem%20solving%20among%20many%20others%2C%20and%20improve%20their%20*mental%20well-being*.%0A%0AWe%20are%20working%20with%20700%2B%20children%20currently%2C%20in%2011%20shelter%20homes%2C%205%20community%20centers%2C%20and%205%20village%20centers.%0A%0ASupport%20us%20to%20enable%20quality%20education%20for%20our%20children%20ensuring%20their%20future%20is%20secured.%0A%0ATo%20know%20more%2C%20please%20visit%20www.muskurahat.org.in%0A%0APlease%20donate%20by%20going%20on%20this%20link%3A%20https%3A%2F%2Fdonate.muskurahat.org.in%2F%3Fr%3D${
        //     doc.data().referenceCode
        //   }%0ATo%20know%20more%2C%20please%20visit%20www.muskurahat.org.in%0A%0AReferral%20Code%3A%20${
        //     doc.data().referenceCode
        //   }%0A(Please%20keep%20in%20mind%20to%20check%20the%20reference%20code%20while%20donating.%20It%20will%20help%20me%20track%20my%20efforts.)%0A%0AOur%20collective%20support%20can%20enable%20this%20much-needed%20change.%20%0ALooking%20forward%20to%20your%20help%2C%20Thank%20you.`
        // );
        if (doc.data().transactions?.length > 0) {
          setDonations([]);
          const newItem = doc
            .data()
            .transactions.map((t, i) =>
              t.get().then((result) => {
                try {
                  return {
                    id: i + 1,
                    name: result.data().name,
                    amount: result.data().amount,
                    transactionId: result.id,
                    date: result.data().date.toDate().toDateString(),
                  };
                } catch (e) {
                  console.log("here" + e + result.id);
                }
              })
            )
            .reverse();

          Promise.all(newItem).then(setDonations);
        }
      });
  }, []);

  function ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  useEffect(() => {
    if (profile?.expiryTime) {
      const eventdate = moment(profile?.expiryTime.toDate());
      const startdate = moment(profile?.accountCreationTime.toDate());
      const todaysdate = moment();
      const daysLeft = eventdate.diff(todaysdate, "days");
      const daysSinceStart = todaysdate.diff(startdate, "days");
      setExpiryDaysLeft(daysLeft);
      setCampaignExpiry(
        daysLeft > 0 ? daysLeft + " Days Left" : "Campaign Expired"
      );
      if (profile?.acheivedGoal < 500 && daysSinceStart > 5) {
        setCelebration({
          title:
            "Initial push is the toughest! Go through the learning modules, or reach out to your fundraising manager to level up. 💪",
          buttonText: "Learning Modules",
        });
      }
      if (profile)
        if (profile?.celebrate) {
          var duration = 10 * 1000;
          var animationEnd = Date.now() + duration;
          var defaults = {
            startVelocity: 30,
            spread: 360,
            ticks: 60,
            zIndex: 0,
          };

          function randomInRange(min, max) {
            return Math.random() * (max - min) + min;
          }

          var interval = setInterval(function () {
            var timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) {
              return clearInterval(interval);
            }

            var particleCount = 50 * (timeLeft / duration);
            // since particles fall down, start a bit higher than random
            confetti(
              Object.assign({}, defaults, {
                particleCount,
                zIndex: 1051,
                origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
              })
            );
            confetti(
              Object.assign({}, defaults, {
                particleCount,
                zIndex: 1051,
                origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
              })
            );
          }, 250);

          setInterval(
            firebase_app
              .firestore()
              .collection("profiles")
              .doc(localStorage.getItem("userId"))
              .update({ celebrate: false }),
            5000
          );

          if (profile?.acheivedGoal > 30000) {
            setCelebration({
              title: "Congratulations, You have reached Legend! 🥳",
              buttonText: "Yay!",
            });
            return;
          } else if (profile?.acheivedGoal > 15000) {
            console.log("HEREREERER");
            setCelebration({
              title: "Congratulations, You have reached Wizard! 🥳",
              buttonText: "Yay!",
            });
            return;
          } else if (profile?.acheivedGoal > 5000)
            setCelebration({
              title: "Congratulations, You have reached Ninja! 🥳",
              buttonText: "Yay!",
            });
          else if (profile?.acheivedGoal > 24000)
            setCelebration({
              title:
                "Congratulations, You have single handedly supported 2 children for a year! 🥳",
              buttonText: "Yay!",
            });
          else if (profile?.acheivedGoal > 12000)
            setCelebration({
              title:
                "Congratulations, You have single handedly supported a child for a year! 🥳",
              buttonText: "Yay!",
            });
          else if (profile?.acheivedGoal > 6000)
            setCelebration({
              title:
                "Congratulations, You have single handedly supported a child for half a year! 🥳",
              buttonText: "Yay!",
            });
          else setCelebration("");
        } else if (daysLeft <= 5) {
          if (localStorage.getItem("tourdone") === "true")
            if (daysLeft < 0) {
              setCampaignExpired(true);
            } else {
              setCelebration({
                title: `${daysLeft} Days to go! ⌚`,
                buttonText: "Okay, I’ve got this!",
              });
            }
        }
    }

    if (profile?.acheivedGoal > 1000)
      if (profile?.acheivedGoal > 25000) {
        firebase_app
          .firestore()
          .collection("profiles")
          .where("isActive", "==", true)
          // .where("acheivedGoal", ">", 25000)
          .orderBy("acheivedGoal", "desc")
          .limit(30)
          .get()
          .then((snapshot) => {
            snapshot.docs.map((d) => console.log(d.data()));
            const rank =
              snapshot.docs.findIndex((doc) => doc.id === profile?.id) + 1;
            if (rank !== 0)
              setWelcomeContent(
                `You are the ${ordinal_suffix_of(rank)} highest fundraiser! 🎉`
              );
            else
              setWelcomeContent(
                `You have successfully supported a child for ${Math.floor(
                  profile?.acheivedGoal / 1000
                )} months`
              );
          });
      } else
        setWelcomeContent(
          `You have successfully supported a child for ${Math.floor(
            profile?.acheivedGoal / 1000
          )} months`
        );
    if (profile?.acheivedGoal < 1000)
      setWelcomeContent(
        `Initial push is the toughest! Go through the learning modules, or reach out to your fundraising manager to level up.`
      );
  }, [profile]);

  const normalise = (value, total) => {
    return ((value * 100) / parseInt(total || profile?.goal)).toFixed(2);
  };

  const copyLink = () => {
    navigator.clipboard
      .writeText("https://donate.muskurahat.org.in/?r=" + profile.referenceCode)
      .then(() => {
        alert("Link is now copied to the clipboard!");
      });
  };

  const copyLinkVidyasaarthi = () => {
    navigator.clipboard
      .writeText(
        "https://donate.muskurahat.org.in/vidyasaarthi?r=" +
          profile.referenceCode
      )
      .then(() => {
        alert("Link is now copied to the clipboard!");
      });
  };

  const getLevel = (val) =>
    val > 30000
      ? "Legend"
      : val > 15000
      ? "Wizard"
      : val > 5000
      ? "Ninja"
      : "Star";

  const getNextLevel = (val) =>
    val > 30000 ? val : val > 15000 ? 30000 : val > 5000 ? 15000 : 5000;

  const getUnlockText = (val) => {
    let level =
      val > 30000
        ? ""
        : val > 15000
        ? "Legend"
        : val > 5000
        ? "Wizard"
        : "Ninja";
    let nextVal =
      val > 30000 ? "" : val > 15000 ? "30000" : val > 5000 ? "15000" : "5000";

    return val > 45000 ? (
      <>You are a Legend Level Fundraiser</>
    ) : (
      <>
        Unlock <span>{level} Level</span> at {nextVal}
      </>
    );
  };

  const extendCampaign = () => {
    const extendedExpiry = moment().add(10, "days").toDate();
    firebase_app
      .firestore()
      .collection("profiles")
      .doc(localStorage.getItem("userId"))
      .update({ expiryTime: extendedExpiry, isActive: true })
      .then(() => {
        firebase_app
          .firestore()
          .collection("referenceCodes")
          .doc("referenceCodes")
          .update({
            referenceCodes: firebase.firestore.FieldValue.arrayUnion(
              profile?.referenceCode
            ),
          });

        setCampaignExpired(false);
      });
  };

  const CampaignExpiredModal = () => (
    <Modal
      isOpen={campaignExpired}
      toggle={() => setCampaignExpired(false)}
      centered
    >
      <ModalHeader toggle={() => setCampaignExpired(false)}>
        {"Your Campaign is expired"}
      </ModalHeader>
      <ModalBody>
        <h4 className="f-18" style={{ textAlign: "center" }}>
          Extend your campaign here <CornerLeftDown />
        </h4>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={extendCampaign}>
          {"Extend Campaign"}
        </Button>
      </ModalFooter>
    </Modal>
  );

  const shareOnWhatsapp = () => {
    var win = window.open(whatsappLink, "_blank");
    win.focus();
  };

  return (
    <Fragment>
      <Breadcrumb parent="General" title={"Dashboard"} />
      {/* {profile && !tourDone && (
        <Tour
          steps={steps}
          rounded={5}
          isOpen={opentour}
          disableInteraction={true}
          disableKeyboardNavigation={false}
          onRequestClose={closeTour}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
          showCloseButton={false}
          closeWithMask={false}
          updateDelay={300}
          lastStepNextButton={
            <Button color="primary" onClick={closeTour}>
              Next
            </Button>
          }
        />
      )} */}
      {profile?.tourdone && <CampaignExpiredModal />}
      {profile?.tourdone && celebration.title && (
        <Modal isOpen={celebrate} toggle={() => setCelebrate(false)} centered>
          <Card style={{ marginBottom: 0 }}>
            <CardBody>
              <h4 style={{ textAlign: "center" }}>{celebration.title}</h4>
            </CardBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => {
                  celebration.buttonText === "Learning Modules"
                    ? history.push("/dashboard/learning-modules")
                    : setCelebrate(false);
                }}
              >
                {celebration.buttonText}
              </Button>
              {celebration.buttonText === "Learning Modules" && (
                <Button color="primary" onClick={() => setCelebrate(false)}>
                  Continue
                </Button>
              )}
            </ModalFooter>
          </Card>
        </Modal>
      )}
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col xl="12 xl-100" className="risk-col box-col-12">
            <Card className="total-users features-faq product-box">
              <CardBody className="p-0">
                {/* <div className={getRibbon()}></div> */}
                <div className="general-img faq-image product-img">
                  {/* <img
                    className="img-fluid"
                    style={{ maxHeight: "70vh", filter: "blur(1px)" }}
                    src={team}
                    alt=""
                  /> */}

                  <div className="product-hover" style={{ color: "#fff" }}>
                    <div
                      style={{
                        padding: 30,
                        background: "#0000007a",
                      }}
                    >
                      <h2 style={{ color: "#ea4633" }}>
                        Hello {profile?.name},{" "}
                      </h2>
                      <br />
                      <h6>
                        <i>{welcomeContent}</i>
                      </h6>
                    </div>
                  </div>
                </div>

                <br />
                <div className="apex-chart-container goal-status text-center row">
                  <div className="rate-card col-xl-12">
                    <div className="goal-chart">
                      {profile ? (
                        <ApexCharts
                          id="riskfactorchart"
                          options={{
                            chart: {
                              height: 350,
                              type: "radialBar",
                              offsetY: -10,
                            },

                            plotOptions: {
                              radialBar: {
                                startAngle: -135,
                                endAngle: 135,
                                inverseOrder: true,
                                hollow: {
                                  margin: 5,
                                  size: "60%",
                                  // image: radialimage,
                                  imageWidth: 140,
                                  imageHeight: 140,
                                  imageClipped: false,
                                },
                                track: {
                                  opacity: 0.4,
                                  colors: "#ea4633",
                                },
                                dataLabels: {
                                  value: {
                                    show: true,
                                    fontSize: "24px",
                                    formatter: function (val, opts) {
                                      return "₹" + profile
                                        ? profile?.acheivedGoal
                                        : "0";
                                    },
                                  },
                                  // enabled: false,
                                  // enabledOnSeries: undefined,

                                  // textAnchor: "middle",
                                  // distributed: false,
                                  // offsetX: 0,
                                  // offsetY: 0,

                                  style: {
                                    fontSize: "14px",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fill: ["#2b2b2b"],
                                  },
                                },
                              },
                            },

                            fill: {
                              type: "gradient",
                              gradient: {
                                shade: "light",
                                shadeIntensity: 0.15,
                                inverseColors: false,
                                opacityFrom: 1,
                                opacityTo: 1,
                                stops: [0, 100],
                                gradientToColors: ["#4b53a4"],
                                type: "horizontal",
                              },
                            },
                            stroke: {
                              dashArray: 15,
                              strokecolor: ["#ffffff"],
                            },

                            labels: [`Goal Achieved`],
                            colors: ["#ea4633"],
                          }}
                          series={[normalise(profile?.acheivedGoal)]}
                          type="radialBar"
                          height={350}
                        />
                      ) : null}
                    </div>
                    <div className="goal-end-point">
                      <ul>
                        {/* <li className="mt-0 pt-0">
                          <h6 className="font-primary f-w-400">
                            {"Fundraiser Since"}
                          </h6>
                          <h6 className="f-w-400">
                            {profile?.accountCreationTime
                              .toDate()
                              .toDateString()}
                          </h6>
                        </li> */}
                        <li>
                          <h6 className="mt-0 pt-0 font-primary mb-2 f-w-400">
                            {"Total Goal"}
                          </h6>
                          <h5 className="mb-0">₹{profile?.goal}</h5>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <ul className="col-xl-12">
                    <li>
                      <div className="goal-detail">
                        <h6>
                          <span className="font-primary">
                            {"Level Achieved"} :{" "}
                          </span>
                          {getLevel(profile?.acheivedGoal)}
                          <br />
                        </h6>
                        {/* <div className="progress sm-progress-bar progress-animate">
                          <div
                            className="progress-gradient-primary"
                            role="progressbar"
                            style={{
                              width: `${normalise(
                                profile?.acheivedGoal,
                                getNextLevel(profile?.acheivedGoal)
                              )}%`,
                            }}
                            aria-valuenow={normalise(profile?.acheivedGoal)}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div> */}
                        <Progress
                          style={{ width: "80%", margin: "auto" }}
                          color="primary"
                          value={normalise(
                            profile?.acheivedGoal,
                            getNextLevel(profile?.acheivedGoal)
                          )}
                          className="sm-progress-bar"
                        />

                        <br />
                        <Link to="/dashboard/rewards">
                          <div
                            className="rewards-btn btn-download btn btn-gradient f-w-500"
                            style={{
                              cursor: "pointer",
                              padding: "0px 10px 5px 10px",
                              marginLeft: 10,
                            }}
                          >
                            <Star
                              style={{
                                width: 20,
                                position: "relative",
                                top: 5,
                              }}
                            />{" "}
                            Rewards
                          </div>
                        </Link>

                        <div
                          className="btn-download copy-link-btn btn btn-gradient f-w-500"
                          onClick={copyLink}
                          style={{
                            cursor: "pointer",
                            marginLeft: 10,

                            padding: "0px 10px 5px 10px",
                          }}
                        >
                          <Copy
                            style={{
                              width: 20,
                              position: "relative",
                              top: 5,
                            }}
                          />{" "}
                          Copy Donation Link
                        </div>

                        <br />
                        <br />
                        <div>
                          <i>{getUnlockText(profile?.acheivedGoal)}</i>
                        </div>
                      </div>
                      <div className="goal-detail">
                        <h6>
                          <span className="font-primary">{"Time Left"}: </span>
                          {campaignExpiry}
                        </h6>
                        {campaignExpiry === "Campaign Expired" ? (
                          <div>
                            <Button color="primary" onClick={extendCampaign}>
                              Extend Now
                            </Button>
                          </div>
                        ) : null}
                        <br />
                        <Progress
                          style={{ width: "80%", margin: "auto" }}
                          color="primary"
                          value={((30 - parseInt(expiryDaysLeft)) / 30) * 100}
                          className="sm-progress-bar"
                        />
                      </div>
                      <div className="goal-detail reference-code mb-0">
                        <h6>
                          <span className="font-primary">
                            {"Reference Code"} :{" "}
                          </span>
                          {profile?.referenceCode}
                        </h6>
                      </div>
                    </li>
                    <li>
                      <Link to="/dashboard/story">
                        <div
                          className="start-here-btn btn-download btn btn-gradient f-w-500"
                          style={{ cursor: "pointer" }}
                        >
                          Start Here
                        </div>
                      </Link>
                    </li>
                    {/* <li>
                      <div
                        className="btn-download btn btn-gradient f-w-500"
                        onClick={copyLinkVidyasaarthi}
                        style={{ cursor: "pointer" }}
                      >
                        Copy Shareable Link for Vidyasaarthi
                      </div>
                    </li> */}
                  </ul>
                </div>
                <br />
              </CardBody>
              <CardFooter>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "10px",
                  }}
                  className="sharing-div"
                >
                  <div
                    className="whatsapp-link btn btn-gradient f-w-500"
                    onClick={shareOnWhatsapp}
                    style={{
                      cursor: "pointer",
                      marginLeft: 10,

                      padding: "0px 10px 5px 10px",
                    }}
                  >
                    <Share
                      style={{
                        width: 20,
                        position: "relative",
                        top: 5,
                      }}
                    />{" "}
                    Share On Whatsapp
                  </div>
                  {/* <div style={{ display: "flex" }}>
                    <div className="whatsapp-link">
                      <a
                        href={whatsappLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Share on whatsapp"
                        style={{ position: "relative" }}
                      >
                        <svg
                          className="MuiSvgIcon-root"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          role="presentation"
                          style={{
                            fill: "rgb(6, 215, 85)",
                            width: 30,
                            // position: "absolute",
                            // top: -5,
                            // left: 5,
                          }}
                        >
                          <path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path>
                        </svg>
                      </a>
                    </div>
                    <div>
                      <a
                        href={
                          "https://www.facebook.com/sharer/sharer.php?u=https://donate.muskurahat.org.in/?r=" +
                          profile?.referenceCode +
                          "&t=Donate-Now"
                        }
                        // onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Share on Facebook"
                        style={{ position: "relative" }}
                      >
                        <svg
                          className="MuiSvgIcon-root"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          style={{
                            fill: "#00f",
                            width: 30,
                            // position: "absolute",
                            // top: -5,
                            // left: 35,
                          }}
                        >
                          <path d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z"></path>
                        </svg>
                      </a>
                    </div>
                  </div> */}
                </div>
              </CardFooter>
            </Card>
          </Col>
          {/* <Col xl="6">
            {profile ? (
              <Card className="o-hidden">
                <CardHeader>
                  // <div className={getRibbon()}></div>
                  <h5>
                    Goal Meter <span className="f-14">({campaignExpiry})</span>
                  </h5>
                </CardHeader>
                <div className="bar-chart-widget">
                  <CardBody className="p-20-px">
                    <div id="chart-widget5">
                      <Chart
                        options={{
                          ...radialChart.options,
                        }}
                        series={[
                          // normalise(
                          //   profile && profile.acheivedGoal
                          //     ? parseInt(profile.acheivedGoal)
                          //     : 0
                          // ) > 100
                          //   ? 100
                          //   : normalise(
                          //       profile && profile.acheivedGoal
                          //         ? parseInt(profile.acheivedGoal)
                          //         : 0
                          //     ),
                          50,
                          40,
                          20,
                          30,
                        ]}
                        height="350"
                        type="radialBar"
                      />
                    </div>
                  </CardBody>
                </div>
              </Card>
            ) : null}
          </Col>
 */}
          {/* <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{"All Donations"}</h5>
              </CardHeader>
              <CardBody>
                <DataTable
                  data={donations}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  persistTableHead
                />
              </CardBody>
            </Card>
          </Col> */}
        </Row>
      </Container>
      <AllotedStudents />
    </Fragment>
  );
};

export default General;
