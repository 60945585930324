import React, { Fragment, useState } from "react";

import { Carousel, CarouselItem, Col, Container, Media, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { firebase_app } from "../../data/config";

const Story = (props) => {
  const history = useHistory();

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
    firebase_app
      .firestore()
      .collection("profiles")
      .doc(localStorage.getItem("userId"))
      .update({ storyStep: nextIndex });
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
    firebase_app
      .firestore()
      .collection("profiles")
      .doc(localStorage.getItem("userId"))
      .update({ storyStep: nextIndex });
  };

  const items = [
    {
      id: 1,
      src: require("../../assets/images/story/0.webp"),
      mobileSrc: require("../../assets/images/story/0-mobile.webp"),
      button: (
        <>
          <p
            onClick={next}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "100px",
              fontSize: 18,
              color: "#fff",
              cursor: "pointer",
            }}
          >
            <span>&#10095;</span>
          </p>
          <button
            className="story-button"
            style={{
              position: "absolute",
              top: "40%",
              left: "58px",
              width: "300px",
              height: "300px",
              background: "#F6A71C00",
              color: "#ffffff00",
              border: "none",
              padding: "8px 24px",
              font: "Poppins",
              fontWeight: 600,
              // boxShadow: "4px 4px 9px #00000033",
            }}
            onClick={next}
          >
            HEAR HEAR
          </button>
        </>
      ),
      mobileBtn: (
        <>
          <p
            onClick={next}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "20px",
              fontSize: 18,
              color: "#fff",
            }}
          >
            <span>&#10095;</span>
          </p>
          <button
            className="story-button"
            style={{
              fontSize: 12,
              position: "absolute",
              border: "none",
              padding: "8px 24px",
              font: "Poppins",
              fontWeight: 600,
              transform: "translateX(-50%)",
              width: "300px",
              height: "300px",
              top: "20px",
              left: "20px",
              background: "rgb(246 167 28 / 0%)",
              color: "rgb(255 255 255 / 0%)",
            }}
            onClick={next}
          >
            HEAR HEAR
          </button>
        </>
      ),
    },
    {
      id: 2,
      src: require("../../assets/images/story/1.webp"),
      mobileSrc: require("../../assets/images/story/1-mobile.webp"),
      button: (
        <>
          <p
            onClick={previous}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "130px",
              fontSize: 18,
              color: "#000",
              cursor: "pointer",
            }}
          >
            <span>&#10094;</span>
          </p>
          <p
            onClick={next}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "100px",
              fontSize: 18,
              color: "#000",
              cursor: "pointer",
            }}
          >
            <span>&#10095;</span>
          </p>
          <button
            className="story-button"
            style={{
              width: "auto",
              position: "absolute",
              bottom: "150px",
              left: "50%",
              background: "#F6A71C",
              color: "#fff",
              border: "none",
              padding: "8px 24px",
              font: "Poppins",
              fontWeight: 600,
              boxShadow: "4px 4px 9px #00000033",
              transform: "translateX(-50%)",
            }}
            onClick={next}
          >
            LETS GO!
          </button>
        </>
      ),
      mobileBtn: (
        <>
          <p
            onClick={previous}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "50px",
              fontSize: 18,
              color: "#000",
            }}
          >
            <span>&#10094;</span>
          </p>
          <p
            onClick={next}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "20px",
              fontSize: 18,
              color: "#000",
            }}
          >
            <span>&#10095;</span>
          </p>
          <button
            className="story-button"
            style={{
              fontSize: 12,
              width: "auto",
              position: "absolute",
              bottom: "50px",
              left: "50%",
              background: "#F6A71C",
              color: "#fff",
              border: "none",
              padding: "8px 24px",
              font: "Poppins",
              fontWeight: 600,
              boxShadow: "4px 4px 9px #00000033",
              transform: "translateX(-50%)",
            }}
            onClick={next}
          >
            LETS GO!
          </button>
        </>
      ),
    },
    {
      id: 3,
      src: require("../../assets/images/story/2.webp"),
      mobileSrc: require("../../assets/images/story/2-mobile.webp"),
      button: (
        <>
          <p
            onClick={previous}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "130px",
              fontSize: 18,
              color: "#000",
              cursor: "pointer",
            }}
          >
            <span>&#10094;</span>
          </p>
          <p
            onClick={next}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "100px",
              fontSize: 18,
              color: "#000",
              cursor: "pointer",
            }}
          >
            <span>&#10095;</span>
          </p>
          <button
            className="story-button"
            style={{
              width: "auto",
              position: "absolute",
              bottom: "20px",
              left: "50%",
              background: "#F6A71C",
              color: "#fff",
              border: "none",
              padding: "8px 24px",
              font: "Poppins",
              fontWeight: 600,
              boxShadow: "4px 4px 9px #00000033",
              transform: "translateX(-50%)",
            }}
            onClick={next}
          >
            NEXT
          </button>
        </>
      ),
      mobileBtn: (
        <>
          <p
            onClick={previous}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "50px",
              fontSize: 18,
              color: "#000",
            }}
          >
            <span>&#10094;</span>
          </p>
          <p
            onClick={next}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "20px",
              fontSize: 18,
              color: "#000",
            }}
          >
            <span>&#10095;</span>
          </p>
          <button
            className="story-button"
            style={{
              fontSize: 12,
              width: "auto",
              position: "absolute",
              bottom: "30px",
              left: "50%",
              background: "#F6A71C",
              color: "#fff",
              border: "none",
              padding: "8px 24px",
              font: "Poppins",
              fontWeight: 600,
              boxShadow: "4px 4px 9px #00000033",
              transform: "translateX(-50%)",
            }}
            onClick={next}
          >
            NEXT
          </button>
        </>
      ),
    },
    {
      id: 4,
      src: require("../../assets/images/story/3.webp"),
      mobileSrc: require("../../assets/images/story/3-mobile.webp"),
      button: (
        <>
          <p
            onClick={previous}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "130px",
              fontSize: 18,
              color: "#000",
              cursor: "pointer",
            }}
          >
            <span>&#10094;</span>
          </p>
          <p
            onClick={next}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "100px",
              fontSize: 18,
              color: "#000",
              cursor: "pointer",
            }}
          >
            <span>&#10095;</span>
          </p>
          <button
            className="story-button"
            style={{
              width: "auto",
              position: "absolute",
              top: "60%",
              left: "108px",
              background: "#F6A71C",
              color: "#fff",
              border: "none",
              padding: "8px 24px",
              font: "Poppins",
              fontWeight: 600,
              boxShadow: "4px 4px 9px #00000033",
            }}
            onClick={next}
          >
            LETS GET STARTED
          </button>
        </>
      ),
      mobileBtn: (
        <>
          <p
            onClick={previous}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "50px",
              fontSize: 18,
              color: "#000",
            }}
          >
            <span>&#10094;</span>
          </p>
          <p
            onClick={next}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "20px",
              fontSize: 18,
              color: "#000",
            }}
          >
            <span>&#10095;</span>
          </p>
          <button
            className="story-button"
            style={{
              fontSize: 12,
              width: "auto",
              position: "absolute",
              top: "170px",
              left: "30px",
              background: "#F6A71C",
              color: "#fff",
              border: "none",
              padding: "8px 24px",
              font: "Poppins",
              fontWeight: 600,
              boxShadow: "4px 4px 9px #00000033",
            }}
            onClick={next}
          >
            LETS GET STARTED
          </button>
        </>
      ),
    },
    {
      id: 5,
      src: require("../../assets/images/story/4.webp"),
      mobileSrc: require("../../assets/images/story/4-mobile.webp"),
      button: (
        <>
          <p
            onClick={previous}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "130px",
              fontSize: 18,
              color: "#000",
              cursor: "pointer",
            }}
          >
            <span>&#10094;</span>
          </p>
          <p
            onClick={next}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "100px",
              fontSize: 18,
              color: "#000",
              cursor: "pointer",
            }}
          >
            <span>&#10095;</span>
          </p>
          <div
            style={{
              position: "absolute",
              bottom: "165px",
              display: "flex",
              flexWrap: "wrap",
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              justifyContent: "center",
              gap: "45px",
            }}
          >
            <a
              className="img-button"
              href="https://drive.google.com/file/d/1-GgdoTiJcW6uRgDJ1Iu7F63WkxzT2EI9/view?usp=sharing"
            >
              <img
                alt="cause"
                style={{
                  width: "180px",
                  background: "#00000000",
                  color: "#fff",
                  border: "none",
                  font: "Poppins",
                  fontWeight: 600,
                  boxShadow: "4px 4px 9px #00000033",
                }}
                src={require("../../assets/images/story/1-button.webp")}
              />
            </a>
            <a
              className="img-button"
              href="https://www.instagram.com/muskurahat_foundation/"
            >
              <img
                alt="cause"
                style={{
                  width: "180px",
                  background: "#00000000",
                  color: "#fff",
                  border: "none",
                  font: "Poppins",
                  fontWeight: 600,
                  boxShadow: "4px 4px 9px #00000033",
                }}
                src={require("../../assets/images/story/2-button.webp")}
              />
            </a>
            <a
              className="img-button"
              href="https://drive.google.com/file/d/10UNw8Mjoq9eXF1xyUGCrbpG67uHuRFHr/view?usp=sharing"
            >
              <img
                alt="cause"
                style={{
                  width: "180px",
                  background: "#00000000",
                  color: "#fff",
                  border: "none",
                  font: "Poppins",
                  fontWeight: 600,
                  boxShadow: "4px 4px 9px #00000033",
                }}
                src={require("../../assets/images/story/3-button.webp")}
              />
            </a>
            <a className="img-button" href="">
              <img
                alt="cause"
                style={{
                  width: "180px",
                  background: "#00000000",
                  color: "#fff",
                  border: "none",
                  font: "Poppins",
                  fontWeight: 600,
                  boxShadow: "4px 4px 9px #00000033",
                }}
                src={require("../../assets/images/story/4-button.webp")}
              />
            </a>
          </div>
          <button
            className="story-button"
            style={{
              width: "auto",
              position: "absolute",
              bottom: "65px",
              left: "50%",
              background: "#F6A71C",
              color: "#fff",
              border: "none",
              padding: "8px 24px",
              font: "Poppins",
              fontWeight: 600,
              boxShadow: "4px 4px 9px #00000033",
              transform: "translateX(-50%)",
            }}
            onClick={next}
          >
            NEXT
          </button>
        </>
      ),
      mobileBtn: (
        <>
          <p
            onClick={previous}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "50px",
              fontSize: 18,
              color: "#000",
            }}
          >
            <span>&#10094;</span>
          </p>
          <p
            onClick={next}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "20px",
              fontSize: 18,
              color: "#000",
            }}
          >
            <span>&#10095;</span>
          </p>
          <div
            style={{
              position: "absolute",
              bottom: "90px",
              display: "flex",
              flexWrap: "wrap",
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <a
              className="img-button"
              href="https://drive.google.com/file/d/1-GgdoTiJcW6uRgDJ1Iu7F63WkxzT2EI9/view?usp=sharing"
            >
              <img
                alt="cause"
                style={{
                  width: "113px",
                  height: "130px",
                  background: "#00000000",
                  color: "#fff",
                  border: "none",
                  font: "Poppins",
                  fontWeight: 600,
                  boxShadow: "4px 4px 9px #00000033",
                }}
                src={require("../../assets/images/story/1-button.webp")}
              />
            </a>
            <a
              className="img-button"
              href="https://www.instagram.com/muskurahat_foundation/"
            >
              <img
                alt="cause"
                style={{
                  width: "113px",
                  height: "130px",
                  background: "#00000000",
                  color: "#fff",
                  border: "none",
                  font: "Poppins",
                  fontWeight: 600,
                  boxShadow: "4px 4px 9px #00000033",
                }}
                src={require("../../assets/images/story/2-button.webp")}
              />
            </a>
            <a
              className="img-button"
              href="https://drive.google.com/file/d/10UNw8Mjoq9eXF1xyUGCrbpG67uHuRFHr/view?usp=sharing"
            >
              <img
                alt="cause"
                style={{
                  width: "113px",
                  height: "130px",
                  background: "#00000000",
                  color: "#fff",
                  border: "none",
                  font: "Poppins",
                  fontWeight: 600,
                  boxShadow: "4px 4px 9px #00000033",
                }}
                src={require("../../assets/images/story/3-button.webp")}
              />
            </a>
            <a className="img-button" href="">
              <img
                alt="cause"
                style={{
                  width: "113px",
                  height: "130px",
                  background: "#00000000",
                  color: "#fff",
                  border: "none",
                  font: "Poppins",
                  fontWeight: 600,
                  boxShadow: "4px 4px 9px #00000033",
                }}
                src={require("../../assets/images/story/4-button.webp")}
              />
            </a>
          </div>
          <button
            className="story-button"
            style={{
              fontSize: 12,
              width: "auto",
              position: "absolute",
              bottom: "25px",
              left: "50%",
              background: "#F6A71C",
              color: "#fff",
              border: "none",
              padding: "8px 24px",
              font: "Poppins",
              fontWeight: 600,
              boxShadow: "4px 4px 9px #00000033",
              transform: "translateX(-50%)",
            }}
            onClick={next}
          >
            NEXT
          </button>
        </>
      ),
    },
    {
      id: 6,
      src: require("../../assets/images/story/5.webp"),
      mobileSrc: require("../../assets/images/story/5-mobile.webp"),
      button: (
        <>
          <p
            onClick={previous}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "130px",
              fontSize: 18,
              color: "#000",
              cursor: "pointer",
            }}
          >
            <span>&#10094;</span>
          </p>
          <p
            onClick={next}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "100px",
              fontSize: 18,
              color: "#000",
              cursor: "pointer",
            }}
          >
            <span>&#10095;</span>
          </p>
          <button
            className="story-button"
            style={{
              width: "auto",
              position: "absolute",
              bottom: "120px",
              left: "103px",
              background: "#F6A71C",
              color: "#fff",
              border: "none",
              padding: "8px 24px",
              font: "Poppins",
              fontWeight: 600,
              boxShadow: "4px 4px 9px #00000033",
            }}
            onClick={next}
          >
            NEXT
          </button>
        </>
      ),
      mobileBtn: (
        <>
          <p
            onClick={previous}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "50px",
              fontSize: 18,
              color: "#000",
            }}
          >
            <span>&#10094;</span>
          </p>
          <p
            onClick={next}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "20px",
              fontSize: 18,
              color: "#000",
            }}
          >
            <span>&#10095;</span>
          </p>
          <button
            className="story-button"
            style={{
              fontSize: 12,
              width: "auto",
              position: "absolute",
              bottom: "25px",
              left: "50%",
              background: "#F6A71C",
              color: "#fff",
              border: "none",
              padding: "8px 24px",
              font: "Poppins",
              fontWeight: 600,
              boxShadow: "4px 4px 9px #00000033",
              transform: "translateX(-50%)",
            }}
            onClick={next}
          >
            NEXT
          </button>
        </>
      ),
    },
    {
      id: 7,
      src: require("../../assets/images/story/6.webp"),
      mobileSrc: require("../../assets/images/story/6-mobile.webp"),
      button: (
        <>
          <p
            onClick={previous}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "130px",
              fontSize: 18,
              color: "#000",
              cursor: "pointer",
            }}
          >
            <span>&#10094;</span>
          </p>

          <button
            className="story-button"
            style={{
              width: "400px",
              height: "400px",
              position: "absolute",
              bottom: "20px",
              left: "53px",
              background: "#F6A71C00",
              color: "#ffffff00",
              border: "none",
              padding: "8px 24px",
              font: "Poppins",
              fontWeight: 600,
              // boxShadow: "4px 4px 9px #00000033",
            }}
            onClick={() => {
              history.push(`${process.env.PUBLIC_URL}/dashboard/general`);
            }}
          >
            BACK TO THE DASHBOARD
          </button>
        </>
      ),
      mobileBtn: (
        <>
          <p
            onClick={previous}
            style={{
              position: "absolute",
              bottom: "-4px",
              right: "50px",
              fontSize: 18,
              color: "#000",
            }}
          >
            <span>&#10094;</span>
          </p>

          <button
            className="story-button"
            style={{
              fontSize: 12,
              position: "absolute",
              border: "none",
              padding: "8px 24px",
              font: "Poppins",
              fontWeight: 600,
              width: "300px",
              height: "300px",
              top: "200px",
              left: "20px",
              background: "rgb(246 167 28 / 0%)",
              color: "rgb(255 255 255 / 0%)",
            }}
            onClick={() => {
              history.push(`${process.env.PUBLIC_URL}/dashboard/general`);
            }}
          >
            BACK TO THE DASHBOARD
          </button>
        </>
      ),
    },
  ];

  return (
    <Fragment>
      {/* <Breadcrumb
        parent="General"
        title={"Crowdfunding 101 - Learning Modules"}
      /> */}
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col lg="12">
            <Row
              className="default-according style-1 faq-accordion"
              id="accordionoc"
            >
              <Col sm="12" style={{}}>
                {window.innerWidth < 768 ? (
                  <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                    interval={null}
                  >
                    {items.map((item) => {
                      return (
                        <CarouselItem
                          onExiting={() => setAnimating(true)}
                          onExited={() => setAnimating(false)}
                          key={item.id}
                        >
                          <Media
                            style={{}}
                            src={item.mobileSrc}
                            className="img-fluid"
                          />
                          {item.mobileBtn}
                        </CarouselItem>
                      );
                    })}
                  </Carousel>
                ) : (
                  <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                    interval={null}
                  >
                    {items.map((item) => {
                      return (
                        <CarouselItem
                          onExiting={() => setAnimating(true)}
                          onExited={() => setAnimating(false)}
                          key={item.id}
                        >
                          <Media
                            style={{
                              height: "calc(100vh - 80px)",
                              margin: "auto",
                            }}
                            src={item.src}
                            className="img-fluid"
                          />
                          {item.button}
                        </CarouselItem>
                      );
                    })}
                  </Carousel>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Story;
