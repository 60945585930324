import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import moment from "moment";

import { AreaSpalineChart } from "../../constant";

import GridTable from "@nadavshaar/react-grid-table";
import { firebase_app } from "../../data/config";
import clsx from "clsx";

const TimeSpecific = (props) => {
  const [activeRange, setActiveRange] = useState("today");
  const [lastVisible, setLastVisible] = useState(null);

  const columns = [
    {
      id: 1,
      field: "name",
      label: "Name",
      sortable: false,
    },
    {
      id: 2,
      field: "email",
      label: "Email Id",
      sortable: false,
    },
    {
      id: 3,
      field: "amount",
      label: "Funds Raised",
      sortable: false,
    },
    {
      id: 4,
      field: "acheivedGoal",
      label: "Total Funds Raised",
      sortable: false,
    },
  ];

  const getRange = () => {
    let fromDate = moment().subtract(1, "days").format("DD-MM-YYYY").split("-");
    let toDate = moment().subtract(1, "days").format("DD-MM-YYYY").split("-");

    if (activeRange === "today") {
      fromDate = moment().format("DD-MM-YYYY").split("-");
      toDate = moment().format("DD-MM-YYYY").split("-");
    }
    if (activeRange === "thisWeek") {
      fromDate = moment().startOf("isoWeek").format("DD-MM-YYYY").split("-");
      toDate = moment().format("DD-MM-YYYY").split("-");
    }
    if (activeRange === "lastWeek") {
      fromDate = moment()
        .subtract(7, "days")
        .startOf("isoWeek")
        .format("DD-MM-YYYY")
        .split("-");
      toDate = moment()
        .subtract(7, "days")
        .endOf("isoWeek")
        .format("DD-MM-YYYY")
        .split("-");
    }

    const range = [];

    range[0] = new Date(`${fromDate[2]}-${fromDate[1]}-${fromDate[0]} 00:00`);
    range[1] = new Date(
      `${toDate[2]}-${toDate[1]}-${parseInt(toDate[0]) + 1} 00:00`
    );
    return range;
  };

  const controller = new AbortController();

  const onRowsReset = (tableManager) => {
    if (tableManager.searchApi.searchText === "") {
      tableManager.rowsApi.setRows([]);
      tableManager.rowsApi.setTotalRows(0);
    } else {
      controller.abort();
    }
  };

  const onRowsRequest = async (requestData, tableManager) => {
    if (requestData.from > 0 && activeRange !== "allTime") {
      return true;
    }
    if (requestData.searchText === "") {
      const response = await new Promise((r) => {
        if (activeRange === "allTime") {
          const query = lastVisible
            ? firebase_app
                .firestore()
                .collection("profiles")
                .orderBy("lastFundRaised", "desc")
                .limit(20)
                .startAfter(lastVisible)
            : firebase_app
                .firestore()
                .collection("profiles")
                .orderBy("lastFundRaised", "desc")
                .limit(20);

          query.get().then((snapshot) => {
            let fundraisers = snapshot.docs.map((doc) => ({
              ...doc.data(),
              amount: doc.data().acheivedGoal,
            }));

            console.log(fundraisers);

            setLastVisible(snapshot.docs[snapshot.docs.length - 1]);

            // setFundraisers(filteredFundraisers);
            return r({
              items: fundraisers,
              totalItems:
                fundraisers.length < 20
                  ? tableManager.rowsApi.rows.length + fundraisers.length
                  : lastVisible
                  ? tableManager.rowsApi.rows.length + 40
                  : 40,
            });
          });
        } else {
          setLastVisible(null);
          const range = getRange();

          firebase_app
            .firestore()
            .collectionGroup("transactions")
            .where("timestamp", ">=", range[0])
            .where("timestamp", "<", range[1])
            .get()
            .then(async (snapshot) => {
              let fundraisers = await Promise.all(
                snapshot.docs.map((doc) =>
                  doc.ref.parent.parent.get().then((document) => ({
                    ...document.data(),
                    amount: doc.data().amount,
                  }))
                )
              );

              let filteredFundraisers = fundraisers.reduce(function (
                accumulator,
                cur
              ) {
                let email = cur.email,
                  found = accumulator.find(function (elem) {
                    return elem.email === email;
                  });

                if (found) found.amount += cur.amount;
                else accumulator.push(cur);
                return accumulator;
              },
              []);

              // setFundraisers(filteredFundraisers);
              return r({
                items: filteredFundraisers,
                totalItems: filteredFundraisers.length,
              });
            });
        }
      });

      return {
        rows: response.items,
        totalRows: response.totalItems,
      };
    } else {
      const response = await new Promise((r) => {
        const searchedRows = tableManager.searchApi.searchRows(
          tableManager.rowsApi.rows
        );
        return r({
          items: searchedRows,
          totalItems: searchedRows.length,
        });
      });

      return {
        rows: response.items,
        totalRows: response.totalItems,
      };
    }
  };

  const ColumnVisibility = ({ tableManager }) => {
    const onTimeChange = (time) => {
      setActiveRange(time);
      tableManager.paginationApi.setPage(1);
      tableManager.asyncApi.resetRows();
    };

    const activeClass = (time) => clsx(activeRange === time ? "active" : null);
    return (
      <div
        style={{
          padding: "10px 20px",
          background: "#fff",
          width: "60%",
        }}
        className="rgt-header-container"
      >
        <Row className="m-0" style={{ width: "100%" }}>
          <Col xl="8" md="8" sm="8" className="col-12 p-0">
            <div className="inner-top-left">
              <ul className="d-flex list-unstyled">
                <li
                  onClick={() => onTimeChange("today")}
                  className={activeClass("today")}
                >
                  {"Today"}
                </li>
                <li
                  onClick={() => onTimeChange("yesterday")}
                  className={activeClass("yesterday")}
                >
                  {"Yesterday"}
                </li>
                <li
                  onClick={() => onTimeChange("thisWeek")}
                  className={activeClass("thisWeek")}
                >
                  {"This Week"}
                </li>
                <li
                  onClick={() => onTimeChange("lastWeek")}
                  className={activeClass("lastWeek")}
                >
                  {"Last Week"}
                </li>
                <li
                  onClick={() => onTimeChange("allTime")}
                  className={activeClass("allTime")}
                >
                  {"All Time"}
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const Loader = ({ tableManager }) => "Loading";

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Fundraisers" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col xl="12">
            <Card className="earning-card">
              <CardBody>
                <div className="chart-right">
                  <Row>
                    <Col xl="12">
                      <CardBody className="p-0">
                        <GridTable
                          columns={columns}
                          onRowsRequest={onRowsRequest}
                          components={{ ColumnVisibility, Loader }}
                          pageSizes={[20]}
                          onRowsReset={onRowsReset}
                        />
                      </CardBody>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TimeSpecific;
