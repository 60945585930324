import React, { Fragment, useEffect, useState, useRef } from "react";
import Breadcrumb from "../../layout/breadcrumb";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import { firebase_app } from "../../data/config";

import { Accordion, Form, Modal } from "react-bootstrap";
import { HelpCircle } from "react-feather";

const Transactions = (props) => {
  const [profile, setProfile] = useState(null);
  const [learningModulesStep, setLearningModulesStep] = useState(null);
  const [takeAssignment, setTakeAssignment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewResults, setViewResults] = useState(false);
  const assignmentFormRef = useRef();

  useEffect(() => {
    firebase_app
      .firestore()
      .collection("profiles")
      .doc(localStorage.getItem("userId"))
      .get()
      .then((doc) => {
        if (doc.exists) {
          setProfile(doc.data());
          console.log(doc.data());
          if (doc.data().learningModulesStep === undefined) {
            setLearningModulesStep("0");
          }
          if (doc.data().learningModulesStep === 0) {
            setLearningModulesStep("0");
          }
          if (doc.data().learningModulesStep > 3) {
            setLearningModulesStep("4");
          }
          if (doc.data().learningModulesStep === 1) {
            setLearningModulesStep("1");
          }
          if (doc.data().learningModulesStep === 2) {
            setLearningModulesStep("2");
          }
          if (doc.data().learningModulesStep === 3) {
            setLearningModulesStep("3");
          }
        }
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    let score = 0;
    let total = 0;

    const parsedData = {};
    parsedData["learningModulesChapter" + learningModulesStep] = {};
    [...data.entries()].forEach((input) => {
      parsedData["learningModulesChapter" + learningModulesStep][input[0]] =
        input[1];
      // get data-correct attribute of input field
      const correctOption = e.target.querySelector(
        `input[name="${input[0]}"][data-correct="true"]`
      )
        ? e.target.querySelector(
            `input[name="${input[0]}"][data-correct="true"]`
          ).value
        : null;
      // if correct attribute is equal to input value, add 1 to score
      if (correctOption === input[1]) {
        score++;
      }
      if (correctOption != null) {
        total++;
      }
    });
    setIsLoading(true);
    parsedData["learningModulesChapter" + learningModulesStep]["timestamp"] =
      new Date();
    parsedData["learningModulesChapter" + learningModulesStep]["score"] = score;
    parsedData["learningModulesChapter" + learningModulesStep]["total"] = total;
    console.log(parsedData);
    firebase_app
      .firestore()
      .collection("profiles")
      .doc(localStorage.getItem("userId"))
      .update({
        learningModulesStep: parseInt(learningModulesStep) + 1,
        ...parsedData,
      })
      .then(() => {
        setProfile((profile) => {
          return {
            ...profile,
            learningModulesStep: parseInt(learningModulesStep) + 1,
            ...parsedData,
          };
        });
        setIsLoading(false);
        setTakeAssignment(false);
        setLearningModulesStep(String(parseInt(learningModulesStep) + 1));
        console.log("Document successfully written!");
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error writing document: ", error);
      });
  };

  const assignmentForm = {
    0: (
      <>
        {[
          {
            question: `What is the primary benefit of using crowdfunding to bring an idea to fruition?`,
            options: [
              { option: `The ability to raise money quickly`, correct: false },
              {
                option: `The ability to reduce complexity and uncertainty`,
                correct: false,
              },
              {
                option: `The ability to reach a wider audience`,
                correct: false,
              },
              {
                option: `The ability to connect with potential investors`,
                correct: true,
              },
            ],
          },
          {
            question: `What are some of the challenges that Simon mentions when trying to bring an idea to fruition?`,
            options: [
              {
                option: `Raising money and managing uncertainty`,
                correct: false,
              },
              {
                option: `Reducing complexity and working with investors`,
                correct: false,
              },
              {
                option: `Connecting with potential donors and building a community`,
                correct: true,
              },
              {
                option: `All of the above.`,
                correct: false,
              },
            ],
          },
          {
            question: `According to Simon, what is the most important aspect of a successful crowdfunding campaign?`,
            options: [
              {
                option: `Having a strong and compelling idea`,
                correct: false,
              },
              {
                option: `Building a community of supporters`,
                correct: true,
              },
              {
                option: `Creating a detailed plan and budget`,
                correct: false,
              },
              {
                option: `All of the above`,
                correct: false,
              },
            ],
          },
          {
            question: `What is the main goal of the video 'Crowdfunding 101: How it enables ideas to take root in society'?`,
            options: [
              {
                option: `To demonstrate the power of crowdfunding`,
                correct: false,
              },
              {
                option: `To teach the basics of crowdfunding`,
                correct: false,
              },
              {
                option: `To show how to crowdfund an idea`,
                correct: false,
              },
              {
                option: `To explain why crowdfunding is the best way to bring an idea to life.`,
                correct: true,
              },
            ],
          },
        ].map((q, i) => (
          <div className="mb-3">
            <p>
              {i + 1}. {q.question}
            </p>
            {q.options.map((o) => (
              <Form.Check
                required
                type="radio"
                data-correct={o.correct}
                id={o.option}
                name={q.question}
                value={o.option}
                label={o.option}
                feedback="You must choose one option."
                feedbackType="invalid"
              />
            ))}
          </div>
        ))}

        <div className="mb-3">
          <p>
            5. Can you give an example of a successful crowdfunding campaign?
            Keep it short and sweet.
          </p>
          <Form.Control
            required
            as="textarea"
            minLength="200"
            name="Can you give an example of a successful crowdfunding campaign? Keep it short and sweet."
          />
        </div>
      </>
    ),
    1: (
      <>
        {[
          {
            question:
              "According to the video, what is the importance of identifying your target audience for a crowdfunding campaign?",
            options: [
              {
                option: "It helps to create a more compelling message",
                correct: true,
              },
              {
                option: "It helps to reach potential donors more efficiently",
                correct: false,
              },
              {
                option: "It helps to create a more visually appealing campaign",
                correct: false,
              },
              {
                option: "It helps to provide more rewards and incentives",
                correct: false,
              },
            ],
          },
          {
            question:
              "How can you identify High Network Individuals (HNIs) for your crowdfunding campaign?",
            options: [
              {
                option:
                  "By researching and identifying individuals with a high level of influence and financial resources",
                correct: true,
              },
              {
                option: "By using social media marketing techniques",
                correct: false,
              },
              {
                option: "By offering discounts for early backers",
                correct: false,
              },
              {
                option: "By creating a visually appealing video",
                correct: false,
              },
            ],
          },
        ].map((q, i) => (
          <div className="mb-3">
            <p>
              {i + 1}. {q.question}
            </p>
            {q.options.map((o) => (
              <Form.Check
                required
                type="radio"
                id={o.option}
                data-correct={o.correct}
                name={q.question}
                value={o.option}
                label={o.option}
              />
            ))}
          </div>
        ))}
        <div className="mb-3">
          <p>
            3. How will you pitch your campaign to a donor using the model shown
            in the video?
            <br />
            A. Introduce yourself.
            <br />
            B. Explain your goals.
            <br />
            C. Explain the value behind your pitch.
            <br />
            Be creative, send us a write-up or a video or an audio note for the
            same.
          </p>
          <Form.Control
            required
            as="textarea"
            minLength="200"
            name="How will you pitch your campaign to a donor using the model shown in the video? Introduce yourself. Explain your goals. Explain the value behind your pitch."
          />
        </div>
      </>
    ),
    2: (
      <>
        {[
          {
            question: `Why is following up with donor important according to Sabri Suby?`,
            options: [
              {
                option: `It helps to maintain a relationship with them`,
                correct: false,
              },
              {
                option: `It helps to increase the chances of securing funding`,
                correct: false,
              },
              {
                option: `It helps to improve the campaign's visibility`,
                correct: false,
              },
              {
                option: `All of the above`,
                correct: true,
              },
            ],
          },
          {
            question: `What is the main strategy Sabri Suby suggests for following up with donors?`,
            options: [
              {
                option: `Being persistent but not aggressive`,
                correct: true,
              },
              {
                option: `Waiting for them to initiate contact`,

                correct: false,
              },
              {
                option: `Sending out occasional updates`,
                correct: false,
              },
              {
                option: `Constantly reaching out with updates and requests for funding`,
                correct: false,
              },
            ],
          },
          {
            question: `According to Sabri Suby, why is it important to be subtle and aggressive at the same time in following up with donors?`,
            options: [
              {
                option: `It helps to maintain a balance between persistence and respect for their time and consideration`,
                correct: true,
              },
              {
                option: `It helps to increase the chances of securing funding`,
                correct: false,
              },
              {
                option: `It helps to improve the campaign's visibility`,
                correct: false,
              },
              {
                option: `It helps to maintain a relationship with them`,
                correct: false,
              },
            ],
          },
        ].map((q, i) => (
          <div className="mb-3">
            <p>
              {i + 1}. {q.question}
            </p>
            {q.options.map((o, j) => (
              <Form.Check
                required
                type="radio"
                id={o.option + j}
                data-correct={o.correct}
                name={q.question}
                value={o.option}
                label={o.option}
              />
            ))}
          </div>
        ))}

        <div className="mb-3">
          <p>
            4. What do you think is the worst thing that can happen when
            following up with potential donors?
          </p>
          <Form.Control
            required
            as="textarea"
            minLength="200"
            name="What do you think is the worst thing that can happen when following up with potential donors?"
          />
        </div>
      </>
    ),
    3: (
      <>
        {[
          {
            question: `What is the main goal of Jia Jiang's social experiment?`,
            options: [
              {
                option: `To overcome his fear of rejection`,
                correct: true,
              },
              {
                option: `Failure is a necessary step to success`,
                correct: false,
              },
              {
                option: `To learn more about human psychology and asking for what you want`,
                correct: false,
              },
              {
                option: `To learn how to handle rejection in a professional setting`,
                correct: false,
              },
              {
                option: `All of the above`,
                correct: false,
              },
            ],
          },
          {
            question: `What did Jia Jiang learn from his social experiment about asking for what you want?`,
            options: [
              {
                option: `How to phrase requests in a way that increases the chances of success`,
                correct: true,
              },
              {
                option: `How to handle rejection in a positive and constructive way`,
                correct: false,
              },
              {
                option: `How to read body language and nonverbal cues`,
                correct: false,
              },
              {
                option: `All of the above`,
                correct: false,
              },
            ],
          },
          {
            question: `How does Jia Jiang suggest dealing with rejection in a professional setting?`,
            options: [
              {
                option: `By focusing on the rejection itself`,
                correct: false,
              },
              {
                option: `By learning from the experience and using it as an opportunity for growth`,
                correct: true,
              },
              {
                option: `By giving up on the goal`,
                correct: false,
              },
              {
                option: `By becoming aggressive`,
                correct: false,
              },
            ],
          },
          {
            question: `According to Jia Jiang, what is the key takeaway from his social experiment?`,
            options: [
              //
              {
                option: `To overcome his fear of rejection`,
                correct: false,
              },
              {
                option: `Failure is a necessary step to success`,
                correct: false,
              },
              {
                option: `Rejection is a sign that you are not good enough`,
                correct: false,
              },
              {
                option: `Asking for what you want is always the right thing to do, regardless of the response.`,
                correct: true,
              },
            ],
          },
        ].map((q, i) => (
          <div className="mb-3">
            <p>
              {i + 1}. {q.question}
            </p>
            {q.options.map((o, j) => (
              <Form.Check
                required
                type="radio"
                id={o.option + j}
                data-correct={o.correct}
                name={q.question}
                value={o.option}
                label={o.option}
              />
            ))}
          </div>
        ))}

        <div className="mb-3">
          <p>5. Why do you think people are afraid of rejection?</p>
          <Form.Control
            required
            as="textarea"
            minLength="200"
            name="Why do you think people are afraid of rejection?"
          />
        </div>
      </>
    ),
  };

  const result = {
    0: (
      <>
        {profile?.learningModulesChapter0 ? (
          <>
            {[
              {
                question: `What is the primary benefit of using crowdfunding to bring an idea to fruition?`,
                options: [
                  {
                    option: `The ability to raise money quickly`,
                    correct: false,
                  },
                  {
                    option: `The ability to reduce complexity and uncertainty`,
                    correct: false,
                  },
                  {
                    option: `The ability to reach a wider audience`,
                    correct: false,
                  },
                  {
                    option: `The ability to connect with potential investors`,
                    correct: true,
                  },
                ],
              },
              {
                question: `What are some of the challenges that Simon mentions when trying to bring an idea to fruition?`,
                options: [
                  {
                    option: `Raising money and managing uncertainty`,
                    correct: false,
                  },
                  {
                    option: `Reducing complexity and working with investors`,
                    correct: false,
                  },
                  {
                    option: `Connecting with potential donors and building a community`,
                    correct: true,
                  },
                  {
                    option: `All of the above.`,
                    correct: false,
                  },
                ],
              },
              {
                question: `According to Simon, what is the most important aspect of a successful crowdfunding campaign?`,
                options: [
                  {
                    option: `Having a strong and compelling idea`,
                    correct: false,
                  },
                  {
                    option: `Building a community of supporters`,
                    correct: true,
                  },
                  {
                    option: `Creating a detailed plan and budget`,
                    correct: false,
                  },
                  {
                    option: `All of the above`,
                    correct: false,
                  },
                ],
              },
              {
                question: `What is the main goal of the video 'Crowdfunding 101: How it enables ideas to take root in society'?`,
                options: [
                  {
                    option: `To demonstrate the power of crowdfunding`,
                    correct: false,
                  },
                  {
                    option: `To teach the basics of crowdfunding`,
                    correct: false,
                  },
                  {
                    option: `To show how to crowdfund an idea`,
                    correct: false,
                  },
                  {
                    option: `To explain why crowdfunding is the best way to bring an idea to life.`,
                    correct: true,
                  },
                ],
              },
            ].map((q, i) => (
              <div className="mb-3">
                <p>
                  {i + 1}. {q.question}
                </p>
                {q.options.map((o) => (
                  <Form.Check
                    disabled
                    isInvalid={
                      profile?.learningModulesChapter0[q.question] &&
                      profile?.learningModulesChapter0[q.question] ===
                        o.option &&
                      !o.correct
                    }
                    isValid={o.correct}
                    type="radio"
                    id={o.option}
                    name={q.question}
                    value={o.option}
                    label={o.option}
                  />
                ))}
              </div>
            ))}
            <div className="mb-3">
              <p>
                5. Can you give an example of a successful crowdfunding
                campaign? Keep it short and sweet.
              </p>
              <Form.Control
                disabled
                value={
                  profile?.learningModulesChapter0[
                    "Can you give an example of a successful crowdfunding campaign? Keep it short and sweet."
                  ]
                }
                name="Can you give an example of a successful crowdfunding campaign? Keep it short and sweet."
              />
            </div>
          </>
        ) : null}
      </>
    ),
    1: (
      <>
        {profile?.learningModulesChapter1 ? (
          <>
            {[
              {
                question:
                  "According to the video, what is the importance of identifying your target audience for a crowdfunding campaign?",
                options: [
                  {
                    option: "It helps to create a more compelling message",
                    correct: true,
                  },
                  {
                    option:
                      "It helps to reach potential donors more efficiently",
                    correct: false,
                  },
                  {
                    option:
                      "It helps to create a more visually appealing campaign",
                    correct: false,
                  },
                  {
                    option: "It helps to provide more rewards and incentives",
                    correct: false,
                  },
                ],
              },
              {
                question:
                  "How can you identify High Network Individuals (HNIs) for your crowdfunding campaign?",
                options: [
                  {
                    option:
                      "By researching and identifying individuals with a high level of influence and financial resources",
                    correct: true,
                  },
                  {
                    option: "By using social media marketing techniques",
                    correct: false,
                  },
                  {
                    option: "By offering discounts for early backers",
                    correct: false,
                  },
                  {
                    option: "By creating a visually appealing video",
                    correct: false,
                  },
                ],
              },
            ].map((q, i) => (
              <div className="mb-3">
                <p>
                  {i + 1}. {q.question}
                </p>
                {q.options.map((o) => (
                  <Form.Check
                    disabled
                    isInvalid={
                      profile?.learningModulesChapter1[q.question] &&
                      profile?.learningModulesChapter1[q.question] ===
                        o.option &&
                      !o.correct
                    }
                    isValid={o.correct}
                    type="radio"
                    id={o.option}
                    name={q.question}
                    value={o.option}
                    label={o.option}
                  />
                ))}
              </div>
            ))}
            <div className="mb-3">
              <p>
                3. How will you pitch your campaign to a donor using the model
                shown in the video?
                <br />
                A. Introduce yourself.
                <br />
                B. Explain your goals.
                <br />
                C. Explain the value behind your pitch.
                <br />
                Be creative, send us a write-up or a video or an audio note for
                the same.
              </p>
              <Form.Control
                disabled
                value={
                  profile?.learningModulesChapter1[
                    "How will you pitch your campaign to a donor using the model shown in the video? Introduce yourself. Explain your goals. Explain the value behind your pitch."
                  ]
                }
                name="How will you pitch your campaign to a donor using the model shown in the video? Introduce yourself. Explain your goals. Explain the value behind your pitch."
              />
            </div>
          </>
        ) : null}
      </>
    ),
    2: (
      <>
        {profile?.learningModulesChapter2 ? (
          <>
            {[
              {
                question: `Why is following up with donor important according to Sabri Suby?`,
                options: [
                  {
                    option: `It helps to maintain a relationship with them`,
                    correct: false,
                  },
                  {
                    option: `It helps to increase the chances of securing funding`,
                    correct: false,
                  },
                  {
                    option: `It helps to improve the campaign's visibility`,
                    correct: false,
                  },
                  {
                    option: `All of the above`,
                    correct: true,
                  },
                ],
              },
              {
                question: `What is the main strategy Sabri Suby suggests for following up with donors?`,
                options: [
                  {
                    option: `Being persistent but not aggressive`,
                    correct: true,
                  },
                  {
                    option: `Waiting for them to initiate contact`,

                    correct: false,
                  },
                  {
                    option: `Sending out occasional updates`,
                    correct: false,
                  },
                  {
                    option: `Constantly reaching out with updates and requests for funding`,
                    correct: false,
                  },
                ],
              },
              {
                question: `According to Sabri Suby, why is it important to be subtle and aggressive at the same time in following up with donors?`,
                options: [
                  {
                    option: `It helps to maintain a balance between persistence and respect for their time and consideration`,
                    correct: true,
                  },
                  {
                    option: `It helps to increase the chances of securing funding`,
                    correct: false,
                  },
                  {
                    option: `It helps to improve the campaign's visibility`,
                    correct: false,
                  },
                  {
                    option: `It helps to maintain a relationship with them`,
                    correct: false,
                  },
                ],
              },
            ].map((q, i) => (
              <div className="mb-3">
                <p>
                  {i + 1}. {q.question}
                </p>
                {q.options.map((o) => (
                  <Form.Check
                    disabled
                    isInvalid={
                      profile?.learningModulesChapter2[q.question] &&
                      profile?.learningModulesChapter2[q.question] ===
                        o.option &&
                      !o.correct
                    }
                    isValid={o.correct}
                    type="radio"
                    id={o.option}
                    name={q.question}
                    value={o.option}
                    label={o.option}
                  />
                ))}
              </div>
            ))}

            <div className="mb-3">
              <p>
                4. What do you think is the worst thing that can happen when
                following up with potential donors?
              </p>
              <Form.Control
                disabled
                value={
                  profile?.learningModulesChapter2[
                    "What do you think is the worst thing that can happen when following up with potential donors?"
                  ]
                }
                name="What do you think is the worst thing that can happen when following up with potential donors?"
              />
            </div>
          </>
        ) : null}
      </>
    ),
    3: (
      <>
        {profile?.learningModulesChapter3 ? (
          <>
            {[
              {
                question: `What is the main goal of Jia Jiang's social experiment?`,
                options: [
                  {
                    option: `To overcome his fear of rejection`,
                    correct: true,
                  },
                  {
                    option: `To learn more about human psychology and asking for what you want`,
                    correct: false,
                  },
                  {
                    option: `To learn how to handle rejection in a professional setting`,
                    correct: false,
                  },
                  {
                    option: `All of the above`,
                    correct: false,
                  },
                ],
              },
              {
                question: `What did Jia Jiang learn from his social experiment about asking for what you want?`,
                options: [
                  {
                    option: `How to phrase requests in a way that increases the chances of success`,
                    correct: true,
                  },
                  {
                    option: `How to handle rejection in a positive and constructive way`,
                    correct: false,
                  },
                  {
                    option: `How to read body language and nonverbal cues`,
                    correct: false,
                  },
                  {
                    option: `All of the above`,
                    correct: false,
                  },
                ],
              },
              {
                question: `How does Jia Jiang suggest dealing with rejection in a professional setting?`,
                options: [
                  {
                    option: `By focusing on the rejection itself`,
                    correct: false,
                  },
                  {
                    option: `By learning from the experience and using it as an opportunity for growth`,
                    correct: true,
                  },
                  {
                    option: `By giving up on the goal`,
                    correct: false,
                  },
                  {
                    option: `By becoming aggressive`,
                    correct: false,
                  },
                ],
              },
              {
                question: `According to Jia Jiang, what is the key takeaway from his social experiment?`,
                options: [
                  {
                    option: `To overcome his fear of rejection`,
                    correct: false,
                  },
                  {
                    option: `Failure is a necessary step to success`,
                    correct: false,
                  },
                  {
                    option: `Rejection is a sign that you are not good enough`,
                    correct: false,
                  },
                  {
                    option: `Asking for what you want is always the right thing to do, regardless of the response.`,
                    correct: true,
                  },
                ],
              },
            ].map((q, i) => (
              <div className="mb-3">
                <p>
                  {i + 1}. {q.question}
                </p>
                {q.options.map((o) => (
                  <Form.Check
                    disabled
                    isInvalid={
                      profile?.learningModulesChapter3[q.question] &&
                      profile?.learningModulesChapter3[q.question] ===
                        o.option &&
                      !o.correct
                    }
                    isValid={o.correct}
                    type="radio"
                    id={o.option}
                    name={q.question}
                    value={o.option}
                    label={o.option}
                  />
                ))}
              </div>
            ))}

            <div className="mb-3">
              <p>5. Why do you think people are afraid of rejection?</p>
              <Form.Control
                disabled
                value={
                  profile?.learningModulesChapter3[
                    "Why do you think people are afraid of rejection?"
                  ]
                }
                name="Why do you think people are afraid of rejection?"
              />
            </div>
          </>
        ) : null}
      </>
    ),
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="General"
        title={"Crowdfunding 101 - Learning Modules"}
      />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col lg="12">
            <Row
              className="default-according style-1 faq-accordion"
              id="accordionoc"
            >
              <Col sm="12">
                <Modal
                  show={takeAssignment}
                  onHide={() => {
                    takeAssignment(false);
                  }}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Assignment</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form onSubmit={onSubmit}>
                      {assignmentForm[learningModulesStep]}
                      <input
                        type="submit"
                        style={{ display: "none" }}
                        ref={assignmentFormRef}
                      />
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      disabled={isLoading}
                      variant="secondary"
                      onClick={() => {
                        setTakeAssignment(false);
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      disabled={isLoading}
                      variant="primary"
                      onClick={() => assignmentFormRef.current.click()}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={typeof viewResults === "number"}
                  onHide={() => {
                    setViewResults(false);
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Result</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{result[viewResults]}</Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setViewResults(false);
                      }}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                {learningModulesStep && (
                  <Accordion defaultActiveKey={learningModulesStep}>
                    <div className="faq-title">
                      <h6>Hello Changemaker, Welcome to Crowdfunding 101!</h6>
                      <p>
                        We have curated a few learning modules to give you a
                        deeper understanding of being a fundraiser. By the end
                        of these modules, you will be a certified crowdfunding
                        expert. Please Note: Taking assignments at the end of
                        each chapter is mandatory. You can only move to chapter
                        2 after finishing the assignment at the end of chapter
                        1.
                      </p>
                    </div>

                    <Card>
                      <CardHeader>
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Card.Header}
                            className="btn btn-link collapsed pl-0"
                            eventKey="0"
                            aria-expanded={true}
                          >
                            <HelpCircle />
                            {
                              "Chapter 1: How it enables ideas to take root in society"
                            }
                          </Accordion.Toggle>
                        </h5>
                      </CardHeader>
                      <Accordion.Collapse eventKey="0">
                        <CardBody>
                          <p style={{ fontSize: 14 }}>
                            With live audience participation, Simon demonstrates
                            what crowdfunding is, how to crowdfund your idea and
                            why you should use crowdfunding to make your idea a
                            reality. Raising Money, working with uncertainty,
                            reducing complexity, are all challenges you will
                            face when trying to make an idea happen, learn how
                            to tackle those with ease.
                          </p>
                          <p style={{ fontSize: 14 }}>
                            <iframe
                              width="560"
                              height="315"
                              src="https://www.youtube.com/embed/hIO0KH9JfsE"
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                            ></iframe>
                          </p>
                          {learningModulesStep === "0" && (
                            <Button
                              color="primary"
                              onClick={() => {
                                setTakeAssignment(true);
                              }}
                              style={{ width: "auto" }}
                            >
                              Take Assignment
                            </Button>
                          )}
                          {parseInt(learningModulesStep) > 0 && (
                            <Button
                              color="success"
                              onClick={() => {
                                setViewResults(0);
                              }}
                              style={{ width: "auto" }}
                            >
                              View Results
                            </Button>
                          )}
                        </CardBody>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <CardHeader>
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Card.Header}
                            className="btn btn-link collapsed pl-0"
                            eventKey="1"
                            aria-expanded={true}
                          >
                            <HelpCircle />
                            {
                              "Chapter 2: Pitching to High Network Individuals: Tips and Tricks"
                            }
                          </Accordion.Toggle>
                        </h5>
                      </CardHeader>
                      <Accordion.Collapse eventKey="1">
                        <CardBody>
                          <p style={{ fontSize: 14 }}>
                            This video provides practical advice on how to
                            effectively pitch your crowdfunding campaign. You
                            will understand the importance of finding the CROWD
                            that will FUND your project. It covers key elements
                            of a successful pitch, such as identifying your
                            target audience (High Network Individuals -HNIs),
                            crafting a compelling message, and building
                            relationships with potential donors.
                          </p>
                          <p style={{ fontSize: 14 }}>
                            <iframe
                              width="560"
                              height="315"
                              src="https://www.youtube.com/embed/Th90Z_pEQ9A"
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                            ></iframe>
                          </p>
                          {learningModulesStep === "1" && (
                            <Button
                              color="primary"
                              onClick={() => {
                                setTakeAssignment(true);
                              }}
                              style={{ width: "auto" }}
                            >
                              Take Assignment
                            </Button>
                          )}
                          {parseInt(learningModulesStep) > 1 && (
                            <Button
                              color="success"
                              onClick={() => {
                                setViewResults(1);
                              }}
                              style={{ width: "auto" }}
                            >
                              View Results
                            </Button>
                          )}
                        </CardBody>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <CardHeader>
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Card.Header}
                            className="btn btn-link collapsed pl-0"
                            eventKey="2"
                            aria-expanded={true}
                          >
                            <HelpCircle />
                            {
                              "Chapter 3: Following Up with Ease: Strategies for Staying Connected"
                            }
                          </Accordion.Toggle>
                        </h5>
                      </CardHeader>
                      <Accordion.Collapse eventKey="2">
                        <CardBody>
                          <p style={{ fontSize: 14 }}>
                            Learn how to be subtle and aggressive with your
                            follow ups at the same time. How important follow
                            ups are. This video provides strategies for staying
                            connected with your potential prospects, in your
                            case donors. It is the founder of a the biggest
                            advertising agency in Australia, King Kong by Sabri
                            Suby.
                          </p>
                          <p style={{ fontSize: 14 }}>
                            <iframe
                              width="560"
                              height="315"
                              src="https://www.youtube.com/embed/5yKWu-awBwU"
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                            ></iframe>
                          </p>
                          {learningModulesStep === "2" && (
                            <Button
                              color="primary"
                              onClick={() => {
                                setTakeAssignment(true);
                              }}
                              style={{ width: "auto" }}
                            >
                              Take Assignment
                            </Button>
                          )}
                          {parseInt(learningModulesStep) > 2 && (
                            <Button
                              color="success"
                              onClick={() => {
                                setViewResults(2);
                              }}
                              style={{ width: "auto" }}
                            >
                              View Results
                            </Button>
                          )}
                        </CardBody>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <CardHeader>
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Card.Header}
                            className="btn btn-link collapsed pl-0"
                            eventKey="3"
                            aria-expanded={true}
                          >
                            <HelpCircle />
                            {
                              "Chapter 4: Facing Rejections: A pathway to new learnings"
                            }
                          </Accordion.Toggle>
                        </h5>
                      </CardHeader>
                      <Accordion.Collapse eventKey="3">
                        <CardBody>
                          <p style={{ fontSize: 14 }}>
                            In a very interesting social experiment, Jia Jiang
                            asked out 100 different things to different people
                            with the expectation of being rejected. Filled with
                            funniest instances of his life, he did not just
                            overcome his fear of rejection but learned so much
                            about human psychology and asking for what you want
                            that it will blow your mind.
                          </p>
                          <p style={{ fontSize: 14 }}>
                            <iframe
                              width="560"
                              height="315"
                              src="https://www.youtube.com/embed/-vZXgApsPCQ"
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                            ></iframe>
                          </p>
                          {learningModulesStep === "3" && (
                            <Button
                              color="primary"
                              onClick={() => {
                                setTakeAssignment(true);
                              }}
                              style={{ width: "auto" }}
                            >
                              Take Assignment
                            </Button>
                          )}
                          {parseInt(learningModulesStep) > 3 && (
                            <Button
                              color="success"
                              onClick={() => {
                                setViewResults(3);
                              }}
                              style={{ width: "auto" }}
                            >
                              View Results
                            </Button>
                          )}
                        </CardBody>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>{" "}
    </Fragment>
  );
};

export default Transactions;
