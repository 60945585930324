import {
  Box,
  Users,
  ShoppingBag,
  GitPullRequest,
  Watch,
  Star,
  HelpCircle,
  List,
  Monitor,
  BookOpen,
  MessageSquare,
  CornerRightDown,
} from "react-feather";
export const MENUITEMS = [
  {
    menutitle: "General",
    menucontent: "Dashboard",
    adminOnly: false,
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/general`,
        title: "Dashboard",
        type: "link",
        icon: Monitor,
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/transactions`,
        title: "Transactions",
        className: "transactions-nav",
        type: "link",
        icon: List,
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/story`,
        title: "Start Here",
        type: "link",
        icon: CornerRightDown,
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/faq`,
        title: "FAQ",
        type: "link",
        icon: HelpCircle,
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/learning-modules`,
        title: "Learning Modules",
        type: "link",
        icon: BookOpen,
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/rewards`,
        className: "rewards-nav",
        title: "Rewards",
        type: "link",
        icon: Star,
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/feedback`,
        title: "Feedback",
        type: "link",
        icon: MessageSquare,
      },
    ],
  },

  {
    menutitle: "Current Fundraisers",
    menucontent: "Current Fundraisers Reports",
    adminOnly: true,
    Items: [
      {
        title: "Edit Batch Labels",
        icon: ShoppingBag,
        type: "link",
        path: `${process.env.PUBLIC_URL}/dashboard/current-edit-batch-labels`,
      },
      // {
      //   title: "Main Batches",
      //   icon: ShoppingBag,
      //   type: "link",
      //   path: `${process.env.PUBLIC_URL}/dashboard/current-main-batch`,
      // },
      {
        title: "Batch Labels",
        icon: ShoppingBag,
        type: "link",
        path: `${process.env.PUBLIC_URL}/dashboard/current-batch-labels`,
      },
      {
        title: "Pending Pancards",
        icon: ShoppingBag,
        type: "link",
        path: `${process.env.PUBLIC_URL}/dashboard/current-pending-pancards`,
      },
      {
        title: "Tag Labels",
        icon: ShoppingBag,
        type: "link",
        path: `${process.env.PUBLIC_URL}/dashboard/current-tag-labels`,
      },
      {
        title: "Time Specific",
        icon: Box,
        type: "link",
        path: `${process.env.PUBLIC_URL}/dashboard/current-time-specific`,
      },
      {
        title: "Custom Time Specific",
        icon: Box,
        type: "link",
        path: `${process.env.PUBLIC_URL}/dashboard/current-custom-time-specific`,
      },
      {
        title: "Category Specific",
        icon: Users,
        type: "link",
        path: `${process.env.PUBLIC_URL}/dashboard/current-category-specific`,
      },
      {
        title: "Time Left",
        icon: Watch,
        type: "link",
        path: `${process.env.PUBLIC_URL}/dashboard/current-time-left`,
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/received-feedbacks`,
        title: "Received Feedbacks",
        type: "link",
        icon: MessageSquare,
      },
    ],
  },

  // {
  //   menutitle: "Fundraisers",
  //   menucontent: "Fundraisers Reports",
  //   Items: [
  //     {
  //       title: "Edit Batch Labels",
  //       icon: ShoppingBag,
  //       type: "link",
  //       path: `${process.env.PUBLIC_URL}/dashboard/edit-batch-labels`,
  //     },
  //     {
  //       title: "Batch Labels",
  //       icon: ShoppingBag,
  //       type: "link",
  //       path: `${process.env.PUBLIC_URL}/dashboard/batch-labels`,
  //     },
  //     {
  //       title: "Time Specific",
  //       icon: Box,
  //       type: "link",
  //       path: `${process.env.PUBLIC_URL}/dashboard/time-specific`,
  //     },
  //     {
  //       title: "Category Specific",
  //       icon: Users,
  //       type: "link",
  //       path: `${process.env.PUBLIC_URL}/dashboard/category-specific`,
  //     },
  //     {
  //       title: "Time Left",
  //       icon: Watch,
  //       type: "link",
  //       path: `${process.env.PUBLIC_URL}/dashboard/time-left`,
  //     },
  //   ],
  // },
];
