import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Container, Modal } from "reactstrap";
import Pancard from "./pancard";
import * as queryString from "query-string";
import { firebase_app } from "../../data/config";
import Address from "./address";

const UpdatePancard = (props) => {
  const [transactionId, setTransactionId] = useState(
    queryString.parse(props.location.search).id
  );
  const [uid, setUid] = useState("");
  const [refererId, setRefererId] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [pancardExists, setPancardExists] = useState(false);
  const [addressExists, setAddressExists] = useState(false);
  const [receiptURL, setReceiptURL] = useState("");

  useEffect(() => {
    firebase_app
      .firestore()
      .collection("transaction")
      .doc(transactionId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const referer = doc.data().referer;
          const addressLineOne = doc.data().addressLineOne;
          if (!addressLineOne) {
            if (referer !== "") {
              firebase_app
                .firestore()
                .collection("profiles")
                .where("referenceCode", "==", referer)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    setRefererId(doc.id);

                    firebase_app
                      .firestore()
                      .collection("profiles")
                      .doc(doc.id)
                      .collection("transactions")
                      .where("transactionId", "==", transactionId)
                      .get()
                      .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                          setUid(doc.id);
                        });
                      });
                  });
                });
            } else {
              setRefererId("qmb9a8051HbKeYl1ZEQxyaSoLsN2");

              firebase_app
                .firestore()
                .collection("profiles")
                .doc("qmb9a8051HbKeYl1ZEQxyaSoLsN2")
                .collection("transactions")
                .where("transactionId", "==", transactionId)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    setIsLoading(false);
                    setUid(doc.id);
                  });
                });
            }
          } else {
            setIsLoading(false);
            setReceiptURL(
              `https://api.muskurahat.org.in/receipt/${transactionId}`
            );
            setAddressExists(true);
          }
        }
      });
  }, []);

  return (
    <Fragment>
      <div className="page-wrapper">
        {addressExists ? (
          <Modal isOpen={addressExists} centered>
            <Card style={{ marginBottom: 0 }}>
              <CardBody>
                <h6 style={{ textAlign: "center" }}>
                  Thank you for submitting your Pancard.
                  <br /> <a href={receiptURL}>Click here</a> to download your
                  receipt.
                </h6>
              </CardBody>
            </Card>
          </Modal>
        ) : null}
        <Container fluid={true} className="p-0 m-0">
          <div className="comingsoon" style={{ background: "#ddd" }}>
            <div className="comingsoon-inner text-center">
              <img
                src={require("../../assets/images/dashboard/team3.jpg")}
                alt=""
                style={{ width: "100%", height: "90vh" }}
              />
              {transactionId && uid ? (
                <Address
                  transactionId={transactionId}
                  transactionUid={uid}
                  refererId={refererId}
                  standalone
                />
              ) : null}
            </div>
          </div>
        </Container>
      </div>
    </Fragment>
  );
};

export default UpdatePancard;
