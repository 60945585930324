import React, { useState, useEffect } from "react";
import man from "../assets/images/dashboard/profile.jpg";
import { Container, Row, Col, Form, Button } from "reactstrap";
import { firebase_app, googleProvider, Jwt_token } from "../data/config";

import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

const Logins = (props) => {
  const [loading, setLoading] = useState(false);

  const params = new URLSearchParams(props.location.search);
  const batch = params.get("batch");

  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(localStorage.getItem("Name"));

  useEffect(() => {
    localStorage.setItem("profileURL", value);
    localStorage.setItem("Name", name);
  }, [value, name]);

  const googleAuth = async () => {
    try {
      firebase_app
        .auth()
        .signInWithPopup(googleProvider)
        .then(function (result) {
          setName(result.user.displayName);
          setValue(result.user.photoURL);
          localStorage.setItem("batch", batch);
          localStorage.setItem("token", Jwt_token);
          setTimeout(() => {
            props.history.push(`${process.env.PUBLIC_URL}/dashboard/general`);
          }, 200);
        });
    } catch (error) {
      setTimeout(() => {
        toast.error(
          "Oppss.. The password is invalid or the user does not have a password."
        );
      }, 200);
    }
  };

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="index.html">
                  <img
                    className="img-fluid for-light"
                    src={require("../assets/images/logo/logo.png")}
                    alt=""
                    style={{ maxWidth: 250 }}
                  />
                  <img
                    className="img-fluid for-dark"
                    src={require("../assets/images/logo/logo.png")}
                    alt=""
                    style={{ maxWidth: 250 }}
                  />
                </a>
              </div>
              <div className="login-main login-tab">
                <Form className="theme-form">
                  <h4>Sign In With Google</h4>
                  <p>{"Start your Fundraising Campaign Today!"}</p>

                  <div className="loginBackgroundImage">
                    {/* <img
                      src={require("../assets/images/dashboard/team.jpg")}
                      style={{ height: 200, width: "100%" }}
                      alt="Muskurahat Foundation"
                     /> */}
                  </div>
                  <div className="form-group mb-0">
                    <Button
                      color="primary"
                      className="btn-block"
                      disabled={loading ? loading : loading}
                      onClick={googleAuth}
                    >
                      {loading ? (
                        "LOADING..."
                      ) : (
                        <>
                          <i className="icon-social-google txt-googleplus"></i>{" "}
                          SignIn With Google
                        </>
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Logins);
