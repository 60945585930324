import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { firebase_app } from "./data/config";
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
import Tour from "reactour";
import { Button } from "reactstrap";

const TourContainer = (props) => {
  const [profile, setProfile] = useState(null);

  const [isNavChanged, setIsNavChanged] = useState(false);
  const steps = [
    {
      selector: ".goal-status",
      content: (
        <div>
          Hello {profile?.name},<br /> This is your Internship Dashbaord!
          <br /> You can track your progress here!!!
        </div>
      ),
      action: () => {
        if (props.history.location.pathname !== "/dashboard/general") {
          if (!isNavChanged) {
            props.history.push("/dashboard/general");
            setIsNavChanged(true);
          }
        }
      },
    },
    {
      selector: ".reference-code",
      content: (
        <div>
          Your reference code is {profile?.referenceCode}
          <br /> Every donation you crack will be recorded under this referral
          code.
        </div>
      ),
      // action: () => {
      //   setIsNavChanged(false);
      // },
    },
    {
      selector: ".copy-link-btn",
      content: (
        <div>
          Copy your unique link from here and share it with your donors.
        </div>
      ),
    },
    // {
    //   selector: ".copy-link-btn",
    //   content: (
    //     <div>
    //       Every donation made using this link will automatically capture your
    //       referral code
    //     </div>
    //   ),
    // },
    // {
    //   selector: ".whatsapp-link",
    //   content: (
    //     <div>
    //       Share a message with your potential donors by clicking on this
    //       Whatsapp Button.
    //     </div>
    //   ),
    //   // action: (node) => {
    //   //   setIsNavChanged(false);

    //   //   if (window.innerWidth <= 991) {
    //   //     if (document.querySelector(".sidebar-wrapper"))
    //   //       document.querySelector(".sidebar-wrapper").className =
    //   //         "sidebar-wrapper close_icon";
    //   //   }
    //   // },
    // },
    // {
    //   selector: ".transactions-nav",
    //   position: "bottom",
    //   style: {
    //     marginTop: "100px",
    //   },
    //   content: (
    //     <div>You can check the record of your donations by clicking here.</div>
    //   ),
    //   action: (node) => {
    //     setTimeout(() => {
    //       if (!isNavChanged) {
    //         props.history.push("/dashboard/general");
    //         setIsNavChanged(true);
    //       }
    //     }, 100);

    //     if (window.innerWidth <= 991) {
    //       if (document.querySelector(".sidebar-wrapper.close_icon"))
    //         document.querySelector(".sidebar-wrapper.close_icon").className =
    //           "sidebar-wrapper";
    //     }
    //   },
    // },
    // {
    //   selector: ".transactions-table",
    //   content: (
    //     <div>
    //       - The donor will also receive a donation receipt on the spot.
    //       <br />- Check your inbox for an email sent to you as well.
    //     </div>
    //   ),
    //   action: (node) => {
    //     if (props.history.location.pathname !== "/dashboard/transactions") {
    //       setIsNavChanged(false);
    //     }
    //     if (!isNavChanged) {
    //       props.history.push("/dashboard/transactions");
    //       setIsNavChanged(true);
    //     }
    //     // set timeout
    //     if (window.innerWidth <= 991) {
    //       if (document.querySelector(".sidebar-wrapper"))
    //         document.querySelector(".sidebar-wrapper").className =
    //           "sidebar-wrapper close_icon";
    //     }
    //   },
    // },
    {
      selector: ".rewards-btn",
      content: <div>Ace your goals and reap the rewards. CLICK HERE!</div>,
      // action: (node) => {
      //   if (window.innerWidth <= 991) {
      //     if (document.querySelector(".sidebar-wrapper.close_icon"))
      //       document.querySelector(".sidebar-wrapper.close_icon").className =
      //         "sidebar-wrapper";
      //   }
      //   if (props.history.location.pathname !== "/dashboard/rewards") {
      //     setIsNavChanged(false);
      //   }
      //   if (!isNavChanged) {
      //     props.history.push("/dashboard/rewards");
      //     setIsNavChanged(true);
      //   }
      // },
    },
    {
      selector: ".start-here-btn",
      content: (
        <div>
          Eager to get started? Click on start here and we will help you get
          going.
        </div>
      ),
      // action: (node) => {
      //   if (window.innerWidth <= 991) {
      //     if (document.querySelector(".sidebar-wrapper.close_icon"))
      //       document.querySelector(".sidebar-wrapper.close_icon").className =
      //         "sidebar-wrapper";
      //   }
      //   if (props.history.location.pathname !== "/dashboard/rewards") {
      //     setIsNavChanged(false);
      //   }
      //   if (!isNavChanged) {
      //     props.history.push("/dashboard/rewards");
      //     setIsNavChanged(true);
      //   }
      // },
    },

    // {
    //   selector: ".star-reward",
    //   content: <div>Star Level</div>,
    //   action: (node) => {
    //     if (!isNavChanged) {
    //       props.history.push("/dashboard/rewards");
    //       setIsNavChanged(true);
    //     }
    //     // set timeout
    //     if (window.innerWidth <= 991) {
    //       if (document.querySelector(".sidebar-wrapper"))
    //         document.querySelector(".sidebar-wrapper").className =
    //           "sidebar-wrapper close_icon";
    //     }
    //   },
    // },
    // {
    //   selector: ".ninja-reward",
    //   content: <div>Ninja Level</div>,
    // },
    // {
    //   selector: ".wizard-reward",
    //   content: <div>Wizard Level</div>,
    // },
    // {
    //   selector: ".legend-reward",
    //   content: <div>Legend Level</div>,
    // },
  ];

  const closeTour = () => {
    firebase_app
      .firestore()
      .collection("profiles")
      .doc(localStorage.getItem("userId"))
      .update({ tourDone: true });
    props.setIsTourNotDone(false);
    clearAllBodyScrollLocks();
    props.history.push("/dashboard/general");
  };

  useEffect(() => {
    firebase_app
      .firestore()
      .collection("profiles")
      .doc(localStorage.getItem("userId"))
      .get()
      .then((doc) => {
        setProfile({ id: doc.id, ...doc.data() });
      });
  }, []);

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  return (
    profile && (
      <Tour
        steps={steps}
        getCurrentStep={(currentStep) => {
          firebase_app
            .firestore()
            .collection("profiles")
            .doc(localStorage.getItem("userId"))
            .update({ tourStep: currentStep });
        }}
        rounded={5}
        isOpen={props.open}
        disableInteraction={true}
        disableKeyboardNavigation={false}
        onRequestClose={closeTour}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        showCloseButton={false}
        closeWithMask={false}
        update={props.history.location.pathname}
        updateDelay={500}
        lastStepNextButton={
          <Button color="primary" onClick={closeTour}>
            Done
          </Button>
        }
      />
    )
  );
};

export default withRouter(TourContainer);
