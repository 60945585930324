import React, { useEffect } from "react";
import { ChevronsUp } from "react-feather";
import { useState } from "react";
import { Fragment } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { firebase_app } from "../../data/config";
import TourContainer from "../../TourContainer";

const Taptop = (props) => {
  const [taptopStyle, setTapTopStyle] = useState("none");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTourNotDone, setIsTourNotDone] = useState(false);

  const schema = yup.object().shape({
    number: yup
      .string()
      .required("Phone Number is required")
      .matches(
        /^[0-9]{10}$/,
        "Please enter 10 digit Phone Number without spaces."
      ),
  });

  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const executeScroll = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleScroll = () => {
    if (window.scrollY > 600) {
      setTapTopStyle("block");
    } else {
      setTapTopStyle("none");
    }
  };

  const ios = () => {
    if (typeof window === `undefined` || typeof navigator === `undefined`)
      return false;

    return /iPhone|iPad|iPod/i.test(navigator.userAgent || navigator.vendor);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    if (localStorage.getItem("userId"))
      firebase_app
        .firestore()
        .collection("profiles")
        .doc(localStorage.getItem("userId"))
        .onSnapshot((doc) => {
          if (!doc.data().whatsappNumber) {
            // if (localStorage.getItem("tourdone") === "true")
            setIsModalOpen(true);
          }
          if (!doc.data().tourDone) {
            setIsTourNotDone(true);
          }
        });
  }, []);

  const submitForm = (data) => {
    firebase_app
      .firestore()
      .collection("profiles")
      .doc(localStorage.getItem("userId"))
      .update({ whatsappNumber: data.number });
    setIsModalOpen(false);
  };

  return (
    <Fragment>
      <div className="tap-top" style={{ display: taptopStyle }}>
        <ChevronsUp onClick={() => executeScroll()} />
      </div>

      <Modal isOpen={isModalOpen} centered>
        <ModalHeader>{"Enter your Whatsapp number:"}</ModalHeader>
        <ModalBody>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(submitForm)}
          >
            <div className="form-row">
              <Col md="12">
                <Label htmlFor="">{"Whatsapp Number"}</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>{"+91"}</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="form-control"
                    name="number"
                    type="text"
                    placeholder="Whatsapp Number"
                    innerRef={register}
                  />
                  <span>{errors.number?.message}</span>
                </InputGroup>
              </Col>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit(submitForm)}>
            {"Save"}
          </Button>
        </ModalFooter>
      </Modal>
      {!isModalOpen && isTourNotDone && (
        <TourContainer
          open={isTourNotDone}
          setIsTourNotDone={setIsTourNotDone}
        />
      )}
    </Fragment>
  );
};

export default Taptop;
