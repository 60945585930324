import moment from "moment";
import React from "react";
import { CSVLink } from "react-csv";
import { Download } from "react-feather";

const DownloadButton = ({ tableManager }) => {
  console.log(tableManager.rowsApi.rows);
  const rawData = tableManager.rowsApi.rows;

  const mapExportJSON = rawData.map((data) => {
    const eventdate = moment(data?.expiryTime?.toDate());
    const todaysdate = moment();
    const daysLeft = eventdate.diff(todaysdate, "days");
    return {
      accountCreationTime: data.accountCreationTime?.toDate().toDateString(),
      batch: data.batch,
      refCode: data.referenceCode,
      name: data.name,
      number: data.whatsappNumber,
      email: data.email,
      amountRaised: data.acheivedGoal,
      assignment1: data.learningModulesChapter0
        ? `${data.learningModulesChapter0.score}/${data.learningModulesChapter0.total}`
        : "Not Attempted",
      assignment2: data.learningModulesChapter1
        ? `${data.learningModulesChapter1.score}/${data.learningModulesChapter1.total}`
        : "Not Attempted",
      assignment3: data.learningModulesChapter2
        ? `${data.learningModulesChapter2.score}/${data.learningModulesChapter2.total}`
        : "Not Attempted",
      assignment4: data.learningModulesChapter3
        ? `${data.learningModulesChapter3.score}/${data.learningModulesChapter3.total}`
        : "Not Attempted",
      numberOfDonors: data.noOfDonors,
      noOfDaysLeft: daysLeft,
      expiry: data.expiryTime?.toDate().toDateString(),
    };
  });

  return (
    <CSVLink data={mapExportJSON} filename="export.csv">
      <Download />
    </CSVLink>
  );
};

export default DownloadButton;
