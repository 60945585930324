import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";
import moment from "moment";
import DatePicker from "react-datepicker";

import { AreaSpalineChart } from "../../constant";

import GridTable from "@nadavshaar/react-grid-table";
import { firebase_app } from "../../data/config";
import clsx from "clsx";
import DownloadButton from "../ui-kits/downloadButton";

const TimeSpecific = (props) => {
  const [activeRange, setActiveRange] = useState("custom");
  const [lastVisible, setLastVisible] = useState(null);

  const columns = [
    {
      id: 1,
      field: "name",
      label: "Name",
      sortable: false,
    },
    {
      id: 2,
      field: "whatsappNumber",
      label: "Whatsapp",
      sortable: false,
    },
    // {
    //   id: 2,
    //   field: "email",
    //   label: "Email Id",
    //   sortable: false,
    // },
    {
      id: 3,
      field: "amount",
      label: "Funds Raised",
      sortable: true,
    },
    {
      id: 4,
      field: "acheivedGoal",
      label: "Total Funds Raised",
      sortable: true,
    },
    {
      id: 5,
      field: "referenceCode",
      label: "Reference Code",
      sortable: false,
    },
    {
      id: 6,
      field: "batch",
      label: "Batch",
      sortable: false,
    },
    {
      id: 7,
      field: "goal",
      label: "No Of Days Left",
      sortable: true,
      cellRenderer: ({ data }) => {
        const eventdate = moment(data?.expiryTime.toDate());
        const todaysdate = moment();
        const daysLeft = eventdate.diff(todaysdate, "days");
        return daysLeft;
        // return daysLeft > 0 ? daysLeft + " Days Left" : "Campaign Expired";
      },
      // sort: ({ a, b, isAscending }) => {
      //   console.log(a, b, isAscending);
      // },
    },
  ];

  const getRange = () => {
    let fromDate = moment().subtract(1, "days").format("DD-MM-YYYY").split("-");
    let toDate = moment().subtract(1, "days").format("DD-MM-YYYY").split("-");

    if (activeRange === "custom") {
      fromDate = moment(startDate).format("DD-MM-YYYY").split("-");
      toDate = moment(endDate).format("DD-MM-YYYY").split("-");
      console.log(fromDate, toDate);
    }

    const range = [];

    range[0] = new Date(`${fromDate[2]}-${fromDate[1]}-${fromDate[0]} 00:00`);
    range[1] = new Date(`${toDate[2]}-${toDate[1]}-${toDate[0]} 23:59`);
    return range;
  };

  const controller = new AbortController();

  const onRowsReset = (tableManager) => {
    if (tableManager.searchApi.searchText === "") {
      tableManager.rowsApi.setRows([]);
      tableManager.rowsApi.setTotalRows(0);
    } else {
      controller.abort();
    }
  };

  const onRowsRequest = async (requestData, tableManager) => {
    if (requestData.from > 0 && activeRange !== "allTime") {
      return true;
    }
    if (requestData.searchText === "") {
      const response = await new Promise((r) => {
        if (activeRange === "allTime") {
          const query = lastVisible
            ? firebase_app
                .firestore()
                .collection("profiles")
                .orderBy("lastFundRaised", "desc")
                .limit(20)
                .startAfter(lastVisible)
            : firebase_app
                .firestore()
                .collection("profiles")
                .orderBy("lastFundRaised", "desc")
                .limit(20);

          query.get().then((snapshot) => {
            let fundraisers = snapshot.docs.map((doc) => ({
              ...doc.data(),
              amount: doc.data().acheivedGoal,
            }));

            console.log(fundraisers);

            setLastVisible(snapshot.docs[snapshot.docs.length - 1]);

            // setFundraisers(filteredFundraisers);
            return r({
              items: fundraisers,
              totalItems:
                fundraisers.length < 20
                  ? tableManager.rowsApi.rows.length + fundraisers.length
                  : lastVisible
                  ? tableManager.rowsApi.rows.length + 40
                  : 40,
            });
          });
        } else {
          setLastVisible(null);
          const range = getRange();

          firebase_app
            .firestore()
            .collectionGroup("transactions")
            .where("timestamp", ">=", range[0])
            .where("timestamp", "<", range[1])
            // .where("isActive", "==", true)
            .get()
            .then(async (snapshot) => {
              let fundraisers = await Promise.all(
                snapshot.docs.map((doc) =>
                  doc.ref.parent.parent.get().then((document) => ({
                    ...document.data(),
                    amount: doc.data().amount,
                  }))
                )
              );

              let filteredFundraisers = fundraisers.reduce(function (
                accumulator,
                cur
              ) {
                let email = cur.email,
                  found = accumulator.find(function (elem) {
                    return elem.email === email;
                  });

                if (found) found.amount += cur.amount;
                else accumulator.push(cur);
                return accumulator;
              },
              []);

              // setFundraisers(filteredFundraisers);
              return r({
                items: filteredFundraisers,
                totalItems: filteredFundraisers.length,
              });
            });
        }
      });

      return {
        rows: response.items,
        totalRows: response.totalItems,
      };
    } else {
      const response = await new Promise((r) => {
        const searchedRows = tableManager.searchApi.searchRows(
          tableManager.rowsApi.rows
        );
        
        return r({
          items: searchedRows,
          totalItems: searchedRows.length,
        });
      });

      return {
        rows: response.items,
        totalRows: response.totalItems,
      };
    }
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const ColumnVisibility = ({ tableManager }) => {
    const search = () => {
      tableManager.paginationApi.setPage(1);
      tableManager.asyncApi.resetRows();
    };

    return (
      <div className="col-md-9 date-range">
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
        <Button color="primary" onClick={search}>
          Search
        </Button>
      </div>
    );
  };

  const Loader = ({ tableManager }) => "Loading";

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Fundraisers" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col xl="12">
            <Card className="earning-card">
              <CardBody>
                <div className="chart-right">
                  <Row>
                    <Col xl="12">
                      <CardBody className="p-0">
                        <GridTable
                          columns={columns}
                          onRowsRequest={onRowsRequest}
                          components={{
                            ColumnVisibility,
                            Loader,
                            PageSize: DownloadButton,
                          }}
                          pageSizes={[20]}
                          onRowsReset={onRowsReset}
                        />
                      </CardBody>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TimeSpecific;
