import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";

import { Container, Row, Col, Card, CardBody } from "reactstrap";

import DataTable from "react-data-table-component";
import confetti from "canvas-confetti";

import { firebase_app } from "../../data/config";
import moment from "moment";

const Transactions = (props) => {
  const [feedbacks, setFeedbacks] = useState([]);

  useEffect(() => {
    firebase_app
      .firestore()
      .collection("feedback")
      .orderBy("timestamp", "desc")
      .get()
      .then((snapshot) => {
        const data = [];
        snapshot.docs.map((d) =>
          data.push({
            ...d.data(),
            timestamp: d.data().timestamp?.toDate().toDateString(),
          })
        );
        setFeedbacks(data);
      });
  }, []);

  const tableColumns = [
    {
      name: "Timestamp",
      selector: "timestamp",
      sortable: true,
      center: true,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
      center: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
    },
    {
      name: "Message",
      selector: "message",
      sortable: true,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb parent="General" title={"Transactions"} />

      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  data={feedbacks}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  persistTableHead
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Transactions;
