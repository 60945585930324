import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";

import { useEffect } from "react";
import { useLocation } from "react-router";
const config = require("./app_config.json");

// Firebase
export const firebase_app = firebase.initializeApp({
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  databaseURL: config.firebase.databaseURL,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
  messagingSenderId: config.firebase.messagingSenderId,
  appId: config.firebase.appId,
});

const tutor_firebase_app = firebase.initializeApp(
  {
    apiKey: config.tutorFirebase.apiKey,
    authDomain: config.tutorFirebase.authDomain,
    databaseURL: config.tutorFirebase.databaseURL,
    projectId: config.tutorFirebase.projectId,
    storageBucket: config.tutorFirebase.storageBucket,
    messagingSenderId: config.tutorFirebase.messagingSenderId,
    appId: config.tutorFirebase.appId,
  },
  "tutor"
);

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const twitterProvider = new firebase.auth.TwitterAuthProvider();
export const githubProvider = new firebase.auth.GithubAuthProvider();
export const db = firebase.firestore();
export const tutor_db = tutor_firebase_app.firestore();

export const analytics = firebase.analytics();

export const FirebaseAnalytics = () => {
  let location = useLocation();
  useEffect(() => {
    const analytics = firebase.analytics;
    if (typeof analytics === "function") {
      const page_path = location.pathname + location.search;
      analytics().setCurrentScreen(page_path);
      analytics().logEvent("page_view", { page_path });
    }
  }, [location]);
  return null;
};
// Auth0
export const auth0 = {
  domain: config.auth0.domain,
  clientId: config.auth0.clientID,
  redirectUri: config.auth0.redirectUri,
};

// Jwt
export const Jwt_token = config.jwt_token;
