import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Bio, Password, Website, Save, EmailAddress } from "../../constant";
import { firebase_app } from "../../data/config";
import man from "../../assets/images/dashboard/profile.jpg";
import { useForm } from "react-hook-form";

const UserEdit = (props) => {
  const [profile, setProfile] = useState(null);
  const [profilePicture, setProfilePicture] = useState("");
  const [updated, setUpdated] = useState(false);
  const { register, handleSubmit, reset, errors } = useForm({
    defaultValues: {
      goal: profile?.goal,
      whatsappNumber: profile?.whatsappNumber,
    },
  });

  useEffect(() => {
    setProfilePicture(localStorage.getItem("profileURL") || man);

    firebase_app
      .firestore()
      .collection("profiles")
      .doc(localStorage.getItem("userId"))
      .onSnapshot((doc) => {
        setProfile({ id: doc.id, ...doc.data() });
        reset({
          goal: doc.data().goal,
          whatsappNumber: doc.data().whatsappNumber,
        });
      });
  }, []);

  const onSubmit = ({ goal, whatsappNumber }) => {
    firebase_app
      .firestore()
      .collection("profiles")
      .doc(localStorage.getItem("userId"))
      .update({ goal, whatsappNumber });
    setUpdated(true);

    setInterval(function () {
      setUpdated(false);
    }, 5000);
  };

  return (
    <Fragment>
      <Breadcrumb parent="Users" title="Edit Profile" />
      <Container fluid={true}>
        <div className="edit-profile">
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <form
                    className="needs-validation"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="form-row">
                      <Row className="mb-2">
                        <div className="col-auto">
                          <Media
                            className="img-70 rounded-circle"
                            alt=""
                            src={profilePicture}
                          />
                        </div>
                        <Col>
                          <h3 className="mb-1">{profile?.name}</h3>
                          <p className="mb-4">{"Fundraiser"}</p>
                        </Col>
                      </Row>
                    </div>
                    <FormGroup>
                      <h6 className="form-label">{EmailAddress}</h6>
                      <Input
                        className="form-control"
                        placeholder="your-email@domain.com"
                        defaultValue={profile?.email}
                        disabled
                      />
                    </FormGroup>
                    <FormGroup>
                      <h6 className="form-label">{"Goal"}</h6>
                      <input
                        className="form-control"
                        name="goal"
                        ref={register({
                          required: true,
                          min: 10000,
                        })}
                      />
                      <span style={{ color: "#ea4633" }}>
                        {errors.goal &&
                          errors.goal.type === "required" &&
                          "Enter Goal"}
                      </span>
                      <span style={{ color: "#ea4633" }}>
                        {errors.goal &&
                          errors.goal.type === "min" &&
                          "Minimum Goal Amount is 10,000"}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <h6 className="form-label">{"Whatsapp Number"}</h6>
                      <input
                        className="form-control"
                        name="whatsappNumber"
                        ref={register({
                          required: true,
                          pattern: /^[6-9]\d{9}$/,
                        })}
                      />
                      <span style={{ color: "#ea4633" }}>
                        {errors.whatsappNumber &&
                          errors.whatsappNumber.type === "required" &&
                          "Enter Whatsapp Number"}
                      </span>
                      <span style={{ color: "#ea4633" }}>
                        {errors.whatsappNumber &&
                          errors.whatsappNumber.type === "pattern" &&
                          "Invalid Whatsapp Number"}
                      </span>
                    </FormGroup>
                    <div className="form-footer">
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                      >
                        {Save}
                      </button>
                      <p
                        style={{
                          color: "#28a745",
                          marginTop: 10,
                          fontSize: 18,
                        }}
                      >
                        {updated && "Your Profile has been updated!"}
                      </p>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default UserEdit;
