import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Input,
  Button,
  Form,
  FormGroup,
} from "reactstrap";
import ApexCharts from "react-apexcharts";
import Knob from "knob";
import { areaSpaline, apexMixedCharts } from "./chartsData/apex-charts-data";
import { AlertItemWithIconInverse } from "../ui-kits/alert/alertComponent";
import { AreaSpalineChart } from "../../constant";
import { toast } from "react-toastify";

import GridTable from "@nadavshaar/react-grid-table";
import { firebase_app } from "../../data/config";
import * as firebase from "firebase/app";

import moment from "moment";
import { Check, Edit, Save, X } from "react-feather";
import clsx from "clsx";
import DownloadButton from "../ui-kits/downloadButton";
import { Typeahead } from "react-bootstrap-typeahead";
import { Controller, useForm } from "react-hook-form";

const TagsForm = ({ rowSelectionApi }) => {
  const [tagsOptions, setTagsOptions] = useState([]);
  useEffect(() => {
    firebase_app
      .firestore()
      .collection("tags")
      .get()
      .then((snapshot) => {
        const tagsArray = snapshot.docs.map((d) => d.data().name);
        setTagsOptions(tagsArray);
      });
  }, []);

  const { register, handleSubmit, errors, control } = useForm();

  const onSubmit = (data) => {
    console.log(data.tags);
    try {
      if (!rowSelectionApi.selectedRowsIds.length > 0) {
        throw new Error("No rows were selected");
      }
      if (data.tags.length < 0 || data.tags === "") {
        let batch = firebase_app.firestore().batch();

        rowSelectionApi.selectedRowsIds.forEach((element) => {
          let docRef = firebase_app
            .firestore()
            .collection("profiles")
            .doc(element);
          batch.update(docRef, {
            tags: [],
          });
        });

        batch.commit().then(() => {
          setTimeout(() => {
            toast.success("Tags are successfully removed");
          }, 200);
        });
      } else {
        data.tags = data.tags.map((d) => {
          if (d.customOption) {
            firebase_app
              .firestore()
              .collection("tags")
              .add({ name: d.label, timestamp: new Date() });
          }
          return d.customOption ? d.label : d;
        });
        let batch = firebase_app.firestore().batch();

        rowSelectionApi.selectedRowsIds.forEach((element) => {
          let docRef = firebase_app
            .firestore()
            .collection("profiles")
            .doc(element);
          batch.update(docRef, {
            tags: firebase.firestore.FieldValue.arrayUnion(...data.tags),
          });
        });

        batch.commit().then(() => {
          setTimeout(() => {
            toast.success("Tags are successfully labeled");
          }, 200);
        });
      }
    } catch (e) {
      setTimeout(() => {
        toast.error(e.message);
      }, 200);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label style={{ fontWeight: 500, marginRight: 10 }}>Batch Tags:</label>
      <div className="form-group mb-0 d-flex">
        <Controller
          as={Typeahead}
          control={control}
          name="tags"
          // rules={{ required: true }}
          id="multiple-typeahead"
          clearButton
          multiple
          allowNew
          newSelectionPrefix="Add a new item: "
          placeholder="Type anything..."
          options={tagsOptions}
          defaultValue=""
        />
        <Button type="submit">Save</Button>
      </div>
    </form>
  );
};

const Revenue = (props) => {
  const [fundraisers, setFundraisers] = useState([]);
  const [label, setLabel] = useState("");

  const [activeRange, setActiveRange] = useState("today");
  const [lastVisible, setLastVisible] = useState(null);

  useEffect(() => {
    const currentDate = moment().format("DD-MM-YYYY").split("-");
    const currentDay = currentDate[0];
    const currentMonth = currentDate[1];
    const currentYear = currentDate[2];

    const unsubscribe = firebase_app
      .firestore()
      .collection("profiles")
      .where(
        "accountCreationTime",
        ">=",
        new Date(`${currentYear}-${currentMonth}-${currentDay}`)
      )
      .where(
        "accountCreationTime",
        "<",
        new Date(`${currentYear}-${currentMonth}-${parseInt(currentDay) + 1}`)
      )
      .onSnapshot((snapshot) => {
        const newItem = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          tags: doc.tags?.join(","),
        }));

        setFundraisers(newItem);
      });
    return () => unsubscribe();
  }, []);

  const getRange = () => {
    let fromDate = moment().subtract(1, "days").format("DD-MM-YYYY").split("-");
    let toDate = moment().subtract(1, "days").format("DD-MM-YYYY").split("-");

    if (activeRange === "today") {
      fromDate = moment().format("DD-MM-YYYY").split("-");
      toDate = moment().format("DD-MM-YYYY").split("-");
    }
    if (activeRange === "thisWeek") {
      fromDate = moment().startOf("isoWeek").format("DD-MM-YYYY").split("-");
      toDate = moment().format("DD-MM-YYYY").split("-");
    }
    if (activeRange === "lastWeek") {
      fromDate = moment()
        .subtract(7, "days")
        .startOf("isoWeek")
        .format("DD-MM-YYYY")
        .split("-");
      toDate = moment()
        .subtract(7, "days")
        .endOf("isoWeek")
        .format("DD-MM-YYYY")
        .split("-");
    }
    if (activeRange === "last15Days") {
      fromDate = moment().subtract(15, "days").format("DD-MM-YYYY").split("-");
      toDate = moment().format("DD-MM-YYYY").split("-");
    }
    if (activeRange === "last30Days") {
      fromDate = moment().subtract(30, "days").format("DD-MM-YYYY").split("-");
      toDate = moment().format("DD-MM-YYYY").split("-");
    }
    if (activeRange === "last45Days") {
      fromDate = moment().subtract(45, "days").format("DD-MM-YYYY").split("-");
      toDate = moment().format("DD-MM-YYYY").split("-");
    }

    if (activeRange === "allTime") {
      fromDate = moment("20200101").format("DD-MM-YYYY").split("-");
      toDate = moment().format("DD-MM-YYYY").split("-");
    }

    const range = [];

    range[0] = new Date(`${fromDate[2]}-${fromDate[1]}-${fromDate[0]} 00:00`);
    range[1] = new Date(
      `${toDate[2]}-${toDate[1]}-${parseInt(toDate[0]) + 1} 00:00`
    );
    return range;
  };

  const controller = new AbortController();

  const onRowsReset = (tableManager) => {
    console.log(tableManager.sortApi.sort.colId);
    if (
      // tableManager.searchApi.searchText === "" &&
      !(typeof tableManager.sortApi.sort.colId === "number")
    ) {
      console.log("here1");
      tableManager.rowsApi.setRows([]);
      tableManager.rowsApi.setTotalRows(0);
    } else {
      console.log("here2");
      controller.abort();
    }
  };

  const onRowsRequest = async (requestData, tableManager) => {
    if (requestData.from > 0 && activeRange !== "allTime") {
      return true;
    }
    console.log(requestData, tableManager);
    // if (requestData.searchText === "") {
    if (typeof tableManager.sortApi.sort.colId === "number") {
      const response = await new Promise((r) => {
        const searchedRows = tableManager.sortApi.sortRows(
          tableManager.rowsApi.rows
        );
        return r({
          items: searchedRows,
          totalItems: searchedRows.length,
        });
      });
      return {
        rows: response.items,
        totalRows: response.totalItems,
      };
    } else {
      const response = await new Promise((r) => {
        setLastVisible(null);
        const range = getRange();

        firebase_app
          .firestore()
          .collection("profiles")
          .where("accountCreationTime", ">=", range[0])
          .where("accountCreationTime", "<", range[1])
          .where("isActive", "==", true)
          .orderBy("accountCreationTime", "desc")
          .get()
          .then(async (snapshot) => {
            let fundraisers = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
              noOfDonors: doc.data().noOfDonors
                ? parseInt(doc.data().noOfDonors)
                : 0,
            }));

            return r({
              items: fundraisers,
              totalItems: fundraisers.length,
            });
          });
      });

      return {
        rows: response.items,
        totalRows: response.totalItems,
      };
    }
    // } else {
    //   const response = await new Promise((r) => {
    //     const searchedRows = tableManager.searchApi.searchRows(
    //       tableManager.rowsApi.rows
    //     );

    //     return r({
    //       items: searchedRows,
    //       totalItems: searchedRows.length,
    //     });
    //   });

    //   return {
    //     rows: response.items,
    //     totalRows: response.totalItems,
    //   };
    // }
  };

  const columns = [
    { id: "checkbox" },
    {
      id: 999,
      field: "mainBatch",
      label: "Main Batch",
      sortable: false,
    },
    {
      id: 3,
      field: "batch",
      label: "Batch",
      sortable: false,
    },
    {
      id: 8,
      field: "referenceCode",
      label: "Reference Code",
      sortable: true,
    },
    {
      id: 2,
      field: "name",
      label: "Name",
      sortable: true,
    },
    {
      id: 1,
      field: "email",
      label: "Email",
      sortable: false,
    },
    {
      id: 5,
      field: "whatsappNumber",
      label: "Whatsapp",
      sortable: false,
    },
    {
      id: 7,
      field: "acheivedGoal",
      label: "Total Funds Raised",
      sortable: false,
    },
    {
      id: 6,
      field: "noOfDonors",
      label: "No Of Donors",
      sortable: true,
    },
    {
      id: 9,
      field: "expiryTime",
      label: "No Of Days Left",
      sortable: true,
      cellRenderer: ({ data }) => {
        const eventdate = moment(data?.expiryTime.toDate());
        const todaysdate = moment();
        const daysLeft = eventdate.diff(todaysdate, "days");
        return daysLeft;
        // return daysLeft > 0 ? daysLeft + " Days Left" : "Campaign Expired";
      },
      sort: ({ a, b, isAscending }) => {
        let aa = moment(a.toDate()).format("l").split("/").reverse().join(),
          bb = moment(b.toDate()).format("l").split("/").reverse().join();
        return aa < bb
          ? isAscending
            ? -1
            : 1
          : aa > bb
          ? isAscending
            ? 1
            : -1
          : 0;
      },
    },
    {
      id: 192,
      label: "Assignment 1",
      field: "learningModulesChapter0",

      cellRenderer: ({ data }) =>
        data.learningModulesChapter0
          ? `${data.learningModulesChapter0.score}/${data.learningModulesChapter0.total}`
          : "Not Attempted",
    },
    {
      id: 193,
      label: "Assignment 2",
      field: "learningModulesChapter1",

      cellRenderer: ({ data }) =>
        data.learningModulesChapter1
          ? `${data.learningModulesChapter1.score}/${data.learningModulesChapter1.total}`
          : "Not Attempted",
    },
    {
      id: 194,
      label: "Assignment 3",
      field: "learningModulesChapter2",

      cellRenderer: ({ data }) =>
        data.learningModulesChapter2
          ? `${data.learningModulesChapter2.score}/${data.learningModulesChapter2.total}`
          : "Not Attempted",
    },
    {
      id: 194,
      label: "Assignment 4",
      field: "learningModulesChapter3",

      cellRenderer: ({ data }) =>
        data.learningModulesChapter3
          ? `${data.learningModulesChapter3.score}/${data.learningModulesChapter3.total}`
          : "Not Attempted",
    },
    {
      id: 4,
      field: "tags",
      label: "Tags",
    },

    {
      id: 10,
      field: "goal",
      label: "Target Amount",
      sortable: false,
    },
    {
      id: 11,
      field: "expiryTime",
      label: "Created on",
      sortable: false,
      cellRenderer: ({ data }) =>
        data.accountCreationTime.toDate().toDateString(),
    },
    {
      id: 11,
      field: "expiryTime",
      label: "Expiry on",
      sortable: false,
      cellRenderer: ({ data }) => data.expiryTime.toDate().toDateString(),
    },
  ];

  const ColumnVisibility = ({ tableManager }) => {
    const { rowSelectionApi } = tableManager;

    const [labelText, setLabelText] = useState("");

    const saveLabel = () => {
      try {
        if (!rowSelectionApi.selectedRowsIds.length > 0) {
          throw new Error("No rows were selected");
        }
        if (labelText === "") {
          throw new Error("Please enter label text");
        }

        let batch = firebase_app.firestore().batch();

        rowSelectionApi.selectedRowsIds.forEach((element) => {
          let docRef = firebase_app
            .firestore()
            .collection("profiles")
            .doc(element);
          batch.update(docRef, { batch: labelText });
        });
        firebase_app
          .firestore()
          .collection("batches")
          .where("name", "==", labelText)
          .get()
          .then((docs) => {
            if (docs.empty) {
              firebase_app
                .firestore()
                .collection("batches")
                .add({ name: labelText, timestamp: new Date() });
            }
          });

        batch.commit().then(() => {
          setTimeout(() => {
            toast.success("Batch is successfully labeled");
          }, 200);
        });
      } catch (e) {
        setTimeout(() => {
          toast.error(e.message);
        }, 200);
      }
    };

    const onTimeChange = (time) => {
      setActiveRange(time);
      tableManager.paginationApi.setPage(1);
      tableManager.sortApi.setSort({ colId: null });
      tableManager.asyncApi.resetRows();
    };

    const activeClass = (time) => clsx(activeRange === time ? "active" : null);
    return (
      <>
        <div>
          <div
            style={{
              // display: "flex",
              // flexDirection: "column",
              padding: "10px 10px",
              background: "#fff",
              // width: "180px",
            }}
            className="rgt-header-container"
          >
            {/* <div> */}
            <label style={{ fontWeight: 500, marginRight: 10 }}>
              Batch label:
            </label>
            <input
              type="text"
              value={labelText}
              onChange={(e) => setLabelText(e.target.value)}
              style={{ width: "50%", marginRight: 10 }}
            />
            <Button onClick={saveLabel}>Save</Button>
            {/* </div> */}
          </div>
          <div
            style={{
              // display: "flex",
              // flexDirection: "column",
              padding: "10px 10px",
              background: "#fff",
              // width: "100%",
            }}
            className="rgt-header-container"
          >
            <TagsForm rowSelectionApi={rowSelectionApi} />
          </div>
          <div
            style={{
              padding: "10px 20px",
              background: "#fff",
              // width: "250px",
            }}
            className="rgt-header-container"
          >
            <Row className="m-0" style={{ width: "100%" }}>
              <Col xl="12" md="12" sm="12" className="col-12 p-0">
                <div className="inner-top-left">
                  <ul className="d-flex flex-wrap list-unstyled">
                    <li
                      onClick={() => onTimeChange("today")}
                      className={activeClass("today")}
                    >
                      {"Today"}
                    </li>
                    <li
                      onClick={() => onTimeChange("yesterday")}
                      className={activeClass("yesterday")}
                    >
                      {"Yesterday"}
                    </li>
                    <li
                      onClick={() => onTimeChange("thisWeek")}
                      className={activeClass("thisWeek")}
                    >
                      {"This Week"}
                    </li>
                    <li
                      onClick={() => onTimeChange("lastWeek")}
                      className={activeClass("lastWeek")}
                    >
                      {"Last Week"}
                    </li>
                    <li
                      onClick={() => onTimeChange("last15Days")}
                      className={activeClass("allTime")}
                    >
                      {"15 Days"}
                    </li>
                    <li
                      onClick={() => onTimeChange("last30Days")}
                      className={activeClass("allTime")}
                    >
                      {"30 Days"}
                    </li>
                    <li
                      onClick={() => onTimeChange("last45Days")}
                      className={activeClass("allTime")}
                    >
                      {"45 Days"}
                    </li>
                    <li
                      onClick={() => onTimeChange("allTime")}
                      className={activeClass("allTime")}
                    >
                      {"All Time"}
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  };

  const Search = ({ tableManager }) => {
    const onTimeChange = (time) => {
      setActiveRange(time);
      tableManager.paginationApi.setPage(1);
      tableManager.asyncApi.resetRows();
    };

    const activeClass = (time) => clsx(activeRange === time ? "active" : null);
    return (
      <div
        style={{
          padding: "10px 20px",
          background: "#fff",
          width: "60%",
        }}
        className="rgt-header-container"
      >
        <Row className="m-0" style={{ width: "100%" }}>
          <Col xl="8" md="8" sm="8" className="col-12 p-0">
            <div className="inner-top-left">
              <ul className="d-flex list-unstyled">
                <li
                  onClick={() => onTimeChange("today")}
                  className={activeClass("today")}
                >
                  {"Today"}
                </li>
                <li
                  onClick={() => onTimeChange("yesterday")}
                  className={activeClass("yesterday")}
                >
                  {"Yesterday"}
                </li>
                <li
                  onClick={() => onTimeChange("thisWeek")}
                  className={activeClass("thisWeek")}
                >
                  {"This Week"}
                </li>
                <li
                  onClick={() => onTimeChange("lastWeek")}
                  className={activeClass("lastWeek")}
                >
                  {"Last Week"}
                </li>
                <li
                  onClick={() => onTimeChange("allTime")}
                  className={activeClass("allTime")}
                >
                  {"All Time"}
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const Loader = ({ tableManager }) => "Loading";

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Fundraisers" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col xl="12">
            <Card className="earning-card">
              <CardHeader>
                <h5>{"Batch Labels"} </h5>
              </CardHeader>
              <CardBody>
                <GridTable
                  columns={columns}
                  onRowsRequest={onRowsRequest}
                  onRowsReset={onRowsReset}
                  pageSizes={[2000]}
                  components={{
                    ColumnVisibility,
                    Loader,
                    PageSize: DownloadButton,
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Revenue;
