import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardBody, Input, Button } from "reactstrap";
import moment from "moment";

import GridTable from "@nadavshaar/react-grid-table";
import { firebase_app } from "../../data/config";
import DownloadButton from "../ui-kits/downloadButton";

const PendingPancards = (props) => {
  const [activeDaysLeft, setActiveDaysLeft] = useState("5");
  const [rows, setRows] = useState([]);
  const [tempDays, setTempDays] = useState("5");

  const columns = [
    {
      id: 1,
      field: "name",
      label: "Name",
      sortable: false,
    },
    {
      id: 2,
      field: "whatsappNumber",
      label: "Whatsapp",
      sortable: false,
    },

    {
      id: 3,
      field: "noOfDonors",
      label: "Total Donors",
      sortable: true,
    },
    {
      id: 333,
      field: "pending",
      label: "Pending Pancards",
      sortable: false,
    },
    {
      id: 4,
      field: "acheivedGoal",
      label: "Total Funds Raised",
      sortable: false,
    },
    {
      id: 5,
      field: "referenceCode",
      label: "Reference Code",
      sortable: false,
    },
    {
      id: 6,
      field: "batch",
      label: "Batch",
      sortable: false,
    },
    {
      id: 7,
      field: "expiryTime",
      label: "No Of Days Left",
      sortable: true,
      cellRenderer: ({ data }) => {
        const eventdate = moment(data?.expiryTime.toDate());
        const todaysdate = moment();
        const daysLeft = eventdate.diff(todaysdate, "days");
        return daysLeft;
        // return daysLeft > 0 ? daysLeft + " Days Left" : "Campaign Expired";
      },
      sort: ({ a, b, isAscending }) => {
        let aa = moment(a.toDate()).format("l").split("/").reverse().join(),
          bb = moment(b.toDate()).format("l").split("/").reverse().join();
        return aa < bb
          ? isAscending
            ? -1
            : 1
          : aa > bb
          ? isAscending
            ? 1
            : -1
          : 0;
      },
    },
    {
      id: 8,
      field: "goal",
      label: "Target Amount",
      sortable: false,
    },
    {
      id: 9,
      field: "expiryTime",
      label: "Expiry on",
      sortable: false,
      cellRenderer: ({ data }) => data.expiryTime.toDate().toDateString(),
    },
  ];

  const getRange = () => {
    const eventdate = moment().add(activeDaysLeft, "days");
    const eventdate2 = moment().add(parseInt(activeDaysLeft) + 1, "days");

    const range = [];

    range[0] = new Date(eventdate);
    range[1] = new Date(eventdate2);

    console.log(range);
    return range;
  };

  useEffect(() => {
    // const range = getRange();
    firebase_app
      .firestore()
      .collectionGroup("transactions")
      .where("pancard", "==", "")
      .orderBy("date", "desc")
      .limit(300)
      .get()
      .then((snapshot) => {
        let donations = snapshot.docs.map((doc) => doc.ref.parent.parent.id);

        let fundraisersRef = donations.reduce(function (acc, curr) {
          return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
        }, {});
        console.log(fundraisersRef);
        // let fundraisers = [];
        let promises = [];
        for (const fundraiser in fundraisersRef) {
          promises.push(
            firebase_app
              .firestore()
              .collection("profiles")
              .doc(fundraiser)
              .get()
              .then((doc) => ({
                ...doc.data(),
                pending: fundraisersRef[fundraiser],
              }))
          );
        }
        Promise.all(promises)
          .then((values) => {
            setRows(values);
          })
          .catch((reason) => {
            console.log(reason);
          });
      });
    // firebase_app
    //   .firestore()
    //   .collection("profiles")
    //   // .where("expiryTime", ">", range[0])
    //   // .where("expiryTime", "<=", range[1])
    //   .where("isActive", "==", true)
    //   .orderBy("expiryTime", "desc")
    //   // .orderBy("acheivedGoal", "desc")
    //   .get()
    //   .then((snapshot) => {
    //     let fundraisers = snapshot.docs.map((doc) => ({
    //       ...doc.data(),
    //       // amount: doc.data().acheivedGoal,
    //     }));

    //     setRows(fundraisers);
    //   });
  }, []);

  const ColumnVisibility = ({ tableManager }) => {
    const onDaysLeftUpdate = () => {
      setActiveDaysLeft(tempDays);
      // tableManager.paginationApi.setPage(1);
      // tableManager.asyncApi.resetRows();
    };

    return (
      <div
        style={{
          padding: "10px 20px",
          background: "#fff",
          width: "60%",
        }}
        className="rgt-header-container"
      >
        <Row className="m-0" style={{ width: "100%" }}>
          <Col xl="8" md="8" sm="8" className="col-12 p-0">
            <div className="inner-top-left" style={{ display: "flex" }}>
              <Input
                className="form-control"
                name="daysLeft"
                type="text"
                placeholder="Days Left"
                value={tempDays}
                onChange={(e) => setTempDays(e.target.value)}
              />{" "}
              <Button onClick={onDaysLeftUpdate} color="primary">
                Search
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const Loader = ({ tableManager }) => "Loading";

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Fundraisers" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col xl="12">
            <Card className="earning-card">
              <CardBody>
                <div className="chart-right">
                  <Row>
                    <Col xl="12">
                      <CardBody className="p-0">
                        <GridTable
                          columns={columns}
                          rows={rows}
                          components={{
                            ColumnVisibility,
                            Loader,
                            PageSize: DownloadButton,
                          }}
                          pageSizes={[50]}
                        />
                      </CardBody>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PendingPancards;
