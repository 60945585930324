import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
} from "reactstrap";
import ApexCharts from "react-apexcharts";
import Knob from "knob";
import { areaSpaline, apexMixedCharts } from "./chartsData/apex-charts-data";

import {
  New,
  Hot,
  Done,
  Pending,
  MixedChart,
  PurchaseOrderValue,
  ProductOrderValue,
  BestSeller,
  AreaSpalineChart,
  Johnketer,
  HerryVenter,
  LoainDeo,
  HorenHors,
  InProcess,
  FenterJessy,
  Success,
} from "../../constant";

import GridTable from "@nadavshaar/react-grid-table";

const Revenue = (props) => {
  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Fundraisers" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col xl="12">
            <Card>
              <CardHeader>
                <h5>{AreaSpalineChart} </h5>
              </CardHeader>
              <CardBody>
                <GridTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Revenue;
