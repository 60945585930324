import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalFooter,
} from "reactstrap";
import { firebase_app } from "../../data/config";

const Address = (props) => {
  const [openModal, setOpenModal] = useState(props.standalone);
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    if (data !== "") {
      console.log(data);
      firebase_app
        .firestore()
        .collection("profiles")
        .doc(props.refererId ? props.refererId : localStorage.getItem("userId"))
        .collection("transactions")
        .doc(props.transactionUid)
        .update(data)
        .then(() =>
          firebase_app
            .firestore()
            .collection("transaction")
            .doc(props.transactionId)
            .update(data)
            .then(() => window.location.reload())
        );

      setOpenModal(false);
    } else {
      errors.showMessages();
    }
  };

  return (
    <>
      {/* <button>Add</button> */}
      {!props.standalone ? (
        <Button
          color="primary"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <i className="fa fa-exclamation"></i>
        </Button>
      ) : null}
      <Modal isOpen={openModal} toggle={() => setOpenModal(false)} centered>
        <Card style={{ marginBottom: 0 }}>
          <CardBody>
            <h4 style={{ textAlign: "center" }}>Update Address</h4>
            <Form
              className="needs-validation"
              noValidate=""
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormGroup>
                <Label htmlFor="addressLineOne">
                  Address Line One <span style={{ color: "#f00" }}>*</span>
                </Label>
                <Input
                  className="form-control"
                  id="addressLineOne"
                  name="addressLineOne"
                  type="text"
                  placeholder=""
                  // style={{ textTransform: "uppercase" }}
                  innerRef={register({
                    required: true,
                    // pattern: /(^$|^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$)/,
                  })}
                />
                <span style={{ color: "#f00" }}>
                  {errors.addressLineOne && "Address Line One is required"}
                </span>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="addressLineTwo">Address Line Two</Label>
                <Input
                  className="form-control"
                  id="addressLineTwo"
                  name="addressLineTwo"
                  type="text"
                  placeholder=""
                  // style={{ textTransform: "uppercase" }}
                  innerRef={register()}
                />
                {/* <span style={{color: "#f00"}}> */}
                {/* {errors.addressLineTwo && "Address Line Two is required"} */}
                {/* </span> */}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="city">
                  City <span style={{ color: "#f00" }}>*</span>
                </Label>
                <Input
                  className="form-control"
                  id="city"
                  name="city"
                  type="text"
                  placeholder=""
                  // style={{ textTransform: "uppercase" }}
                  innerRef={register({
                    required: true,
                    // pattern: /(^$|^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$)/,
                  })}
                />
                <span style={{ color: "#f00" }}>
                  {errors.city && "City is required"}
                </span>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="state">
                  State <span style={{ color: "#f00" }}>*</span>
                </Label>
                <Input
                  className="form-control"
                  id="state"
                  name="state"
                  type="text"
                  placeholder=""
                  // style={{ textTransform: "uppercase" }}
                  innerRef={register({
                    required: true,
                    // pattern: /(^$|^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$)/,
                  })}
                />
                <span style={{ color: "#f00" }}>
                  {errors.state && "State is required"}
                </span>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="pincode">
                  Pincode <span style={{ color: "#f00" }}>*</span>
                </Label>
                <Input
                  className="form-control"
                  id="pincode"
                  name="pincode"
                  type="text"
                  placeholder=""
                  // style={{ textTransform: "uppercase" }}
                  innerRef={register({
                    required: "Pincode is required",
                    pattern: {
                      value: /(^[1-9][0-9]{5}$)/,
                      message: "Incorrect Pincode", // JS only: <p>error message</p> TS only support string
                    },
                  })}
                />
                <span style={{ color: "#f00" }}>
                  {errors.pincode && errors.pincode.message}
                </span>
              </FormGroup>
            </Form>
          </CardBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSubmit(onSubmit)}>
              Continue
            </Button>
          </ModalFooter>
        </Card>
      </Modal>
    </>
  );
};

export default Address;
