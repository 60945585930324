import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import moment from "moment";

import { AreaSpalineChart } from "../../constant";

import GridTable from "@nadavshaar/react-grid-table";
import { firebase_app } from "../../data/config";
import clsx from "clsx";

const Today = (props) => {
  const [lastVisible, setLastVisible] = useState(null);
  const [activeRange, setActiveRange] = useState("yesterday");

  const columns = [
    {
      id: 1,
      field: "name",
      label: "Name",
    },
    {
      id: 2,
      field: "email",
      label: "Email Id",
    },
    {
      id: 3,
      field: "amount",
      label: "Funds Raised",
    },
    {
      id: 4,
      field: "acheivedGoal",
      label: "Total Funds Raised",
    },
  ];

  const getRange = () => {
    let fromDate = moment().subtract(1, "days").format("DD-MM-YYYY").split("-");
    let toDate = moment().subtract(1, "days").format("DD-MM-YYYY").split("-");

    if (activeRange === "today") {
      fromDate = moment().format("DD-MM-YYYY").split("-");
      toDate = moment().format("DD-MM-YYYY").split("-");
    }
    if (activeRange === "thisWeek") {
      fromDate = moment().startOf("isoWeek").format("DD-MM-YYYY").split("-");
      toDate = moment().format("DD-MM-YYYY").split("-");
    }
    if (activeRange === "lastWeek") {
      fromDate = moment()
        .subtract(7, "days")
        .startOf("isoWeek")
        .format("DD-MM-YYYY")
        .split("-");
      toDate = moment()
        .subtract(7, "days")
        .endOf("isoWeek")
        .format("DD-MM-YYYY")
        .split("-");
    }

    const range = [];
    console.log(toDate);
    range[0] = new Date(`${fromDate[2]}-${fromDate[1]}-${fromDate[0]} 00:00`);
    range[1] = new Date(
      `${toDate[2]}-${toDate[1]}-${parseInt(toDate[0]) + 1} 00:00`
    );
    return range;
  };

  const onRowsRequest = async (requestData, tableManager) => {
    const response = await new Promise((r) => {
      const range = getRange();

      firebase_app
        .firestore()
        .collectionGroup("transactions")
        .limit(20)
        .onSnapshot((snapshot) => {
          snapshot.docs.map((doc, i) => {
            console.log(i);
            doc.ref.parent.parent.get().then((a) => console.log(a.data()));
            return null;
          });
        });

      const subQuery = firebase_app
        .firestore()
        .collection("profiles")
        .where("lastFundRaised", ">=", range[0])
        // .where("lastFundRaised", "<", range[1]);
        .orderBy("lastFundRaised", "desc");

      // const subQuery = firebase_app
      //   .firestore()
      //   .collectionGroup("transactions")
      //   .where("timestamp", ">=", range[0])
      //   .where("timestamp", "<", range[1]);

      const query = lastVisible
        ? subQuery.startAfter(lastVisible).limit(20)
        : subQuery.limit(20);

      return query.onSnapshot(async (snapshot) => {
        const newItem = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const amount = await new Promise((re) => {
              firebase_app
                .firestore()
                .collection(`profiles/${doc.id}/transactions`)
                .where("timestamp", ">=", range[0])
                .where("timestamp", "<", range[1])
                .onSnapshot((snapshot) => {
                  const amount = snapshot.docs.reduce(
                    (n, doc) => n + doc.data().amount,
                    0
                  );
                  return re({
                    amount,
                  });
                });
            });

            return {
              id: doc.id,
              ...amount,
              ...doc.data(),
            };
          })
        );

        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        console.log(newItem);

        return r({
          items: newItem,
          totalItems:
            newItem.length < 20
              ? tableManager.rowsApi.rows.length + newItem.length
              : tableManager.rowsApi.rows.length + 40,
        });
      });
    });

    return {
      rows: response.items,
      totalRows: response.totalItems,
    };
  };

  const Header = ({ tableManager }) => {
    const onTimeChange = (time) => {
      setActiveRange(time);
      setLastVisible(null);
      tableManager.asyncApi.resetRows();
    };

    const activeClass = (time) => clsx(activeRange === time ? "active" : null);
    return (
      <div
        style={{
          padding: "10px 20px",
          background: "#fff",
          width: "100%",
        }}
        className="rgt-header-container"
      >
        <Row className="m-0" style={{ width: "100%" }}>
          <Col xl="8" md="8" sm="8" className="col-12 p-0">
            <div className="inner-top-left">
              <ul className="d-flex list-unstyled">
                <li
                  onClick={() => onTimeChange("today")}
                  className={activeClass("today")}
                >
                  {"Today"}
                </li>
                <li
                  onClick={() => onTimeChange("yesterday")}
                  className={activeClass("yesterday")}
                >
                  {"Yesterday"}
                </li>
                <li
                  onClick={() => onTimeChange("thisWeek")}
                  className={activeClass("thisWeek")}
                >
                  {"This Week"}
                </li>
                <li
                  onClick={() => onTimeChange("lastWeek")}
                  className={activeClass("lastWeek")}
                >
                  {"Last Week"}
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Fundraisers" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col xl="12">
            <Card className="earning-card">
              <CardBody>
                <div className="chart-right">
                  <Row>
                    <Col xl="12">
                      <CardBody className="p-0">
                        <GridTable
                          columns={columns}
                          onRowsRequest={onRowsRequest}
                          components={{ Header }}
                        />
                      </CardBody>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Today;
