import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  FormGroup,
  Button,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";

import { firebase_app } from "../../data/config";
import { useForm } from "react-hook-form";

const Transactions = (props) => {
  const { register, handleSubmit, reset, errors } = useForm({
    defaultValues: {},
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = ({ name, email, message }) => {
    setLoading(true);
    firebase_app
      .firestore()
      .collection("feedback")
      .add({ name, email, message, timestamp: new Date() })
      .then(() => {
        setTimeout(() => {
          toast.success(
            "Thank you! Your feedback has been successfully recorded."
          );
        }, 200);

        setLoading(false);

        reset();
      });
  };

  return (
    <Fragment>
      <Breadcrumb parent="General" title={"Feedback"} />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col sm="12">
            <Card>
              <CardBody className="contact-form">
                <form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-icon">
                    <i className="icofont icofont-envelope-open"></i>
                  </div>
                  <FormGroup>
                    <Label for="exampleInputName">{"Your Name"}</Label>
                    <input
                      className="form-control"
                      id="exampleInputName"
                      type="text"
                      placeholder="Himanshu Goenka"
                      name="name"
                      ref={register({
                        required: true,
                      })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="col-form-label"
                      htmlFor="exampleInputEmail1"
                    >
                      {"Email"}
                    </Label>
                    <input
                      className="form-control"
                      id="exampleInputEmail1"
                      type="email"
                      placeholder="himanshu@gmail.com"
                      name="email"
                      ref={register({
                        required: true,
                      })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="col-form-label"
                      htmlFor="exampleInputEmail1"
                    >
                      {"Message"}
                    </Label>
                    <textarea
                      className="form-control textarea"
                      rows="3"
                      cols="50"
                      placeholder="Your Message"
                      name="message"
                      ref={register({
                        required: true,
                      })}
                    ></textarea>
                  </FormGroup>
                  <div className="text-sm-right">
                    <Button
                      disabled={loading}
                      className="btn btn-primary-gradien"
                      type="submit"
                    >
                      {loading ? <Spinner /> : "Submit"}
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Transactions;
