import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  FormGroup,
  Button,
  ModalFooter,
  Label,
  Input,
  Form,
} from "reactstrap";
import { useForm } from "react-hook-form";

import DataTable from "react-data-table-component";
import confetti from "canvas-confetti";

import { firebase_app } from "../../data/config";
import moment from "moment";
import Pancard from "./pancard";

const Transactions = (props) => {
  const [donations, setDonations] = useState([]);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    // const unsubscribe = firebase_app
    //   .firestore()
    //   .collection("profiles")
    //   .doc(localStorage.getItem("userId"))
    //   .onSnapshot((doc) => {
    //     setProfile({ id: doc.id, ...doc.data() });
    //     if (doc.data().transactions?.length > 0) {
    //       setDonations([]);
    //       const newItem = doc
    //         .data()
    //         .transactions.map((t, i) =>
    //           t.get().then((result) => {
    //             try {
    //               return {
    //                 id: i + 1,
    //                 name: result.data().name,
    //                 amount: result.data().amount,
    //                 transactionId: result.id,
    //                 date: result.data().date.toDate().toDateString(),
    //                 pancard: result.data().pancard ? result.data().pancard : "",
    //               };
    //             } catch (e) {
    //               console.log("here" + e + result.id);
    //             }
    //           })
    //         )
    //         .reverse();

    //       Promise.all(newItem).then(setDonations);
    //     }
    //   });
    // return () => unsubscribe();

    firebase_app
      .firestore()
      .collection("profiles")
      .doc(localStorage.getItem("userId"))
      .collection("transactions")
      .orderBy("date", "desc")
      .get()
      .then((querySnapshot) => {
        setDonations([]);
        const items = [];
        let i = 0;
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          items.push({
            id: i + 1,
            name: doc.data().name,
            amount: doc.data().amount,
            transactionId: doc.data().transactionId,
            transactionUid: doc.id,
            date: doc.data().date
              ? doc.data().date.toDate().toDateString()
              : "",
            // date: "",
            pancard: doc.data().pancard ? doc.data().pancard : "",
          });
          i = i + 1;
        });
        // .reverse();
        setDonations(items);
      });
    // .onSnapshot((doc) => {
    //   if (doc.data().transactions?.length > 0) {
    //     const newItem = doc.data().transactions.map((t, i) =>
    //       t.get().then((result) => {
    //         try {
    //           return {
    //             id: i + 1,
    //             name: result.data().name,
    //             amount: result.data().amount,
    //             transactionId: result.id,
    //             date: result.data().date.toDate().toDateString(),
    //             pancard: result.data().pancard ? result.data().pancard : "",
    //           };
    //         } catch (e) {
    //           console.log("here" + e + result.id);
    //         }
    //       })
    //     );

    //     Promise.all(newItem).then(setDonations);
    //   }
    // });
  }, []);

  useEffect(() => {
    if (profile?.expiryTime) {
      const eventdate = moment(profile?.expiryTime.toDate());
      const todaysdate = moment();
      const daysLeft = eventdate.diff(todaysdate, "days");

      if (profile?.celebrate) {
        var duration = 4 * 1000;
        var animationEnd = Date.now() + duration;
        var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

        function randomInRange(min, max) {
          return Math.random() * (max - min) + min;
        }

        var interval = setInterval(function () {
          var timeLeft = animationEnd - Date.now();

          if (timeLeft <= 0) {
            return clearInterval(interval);
          }

          var particleCount = 50 * (timeLeft / duration);
          // since particles fall down, start a bit higher than random
          confetti(
            Object.assign({}, defaults, {
              particleCount,
              zIndex: 1051,
              origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
            })
          );
          confetti(
            Object.assign({}, defaults, {
              particleCount,
              zIndex: 1051,
              origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
            })
          );
        }, 250);
      }
    }
  }, [profile]);

  const tableColumns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      center: true,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
      center: true,
    },
    {
      name: "Amount",
      selector: "amount",
      sortable: true,
      center: true,
    },
    {
      name: "Transaction Id",
      selector: "transactionId",
      sortable: true,
      center: true,
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      center: true,
    },
    {
      name: "Pancard",
      selector: (row) =>
        row.pancard ? (
          row.pancard
        ) : (
          <Pancard
            transactionId={row.transactionId}
            transactionUid={row.transactionUid}
          />
        ),
      sortable: false,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb parent="General" title={"Transactions"} />

      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col sm="12">
            <Card className="transactions-table">
              <CardBody>
                <DataTable
                  data={donations}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  persistTableHead
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Transactions;
