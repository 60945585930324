import React, { Fragment, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Progress,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Media,
  Tooltip,
} from "reactstrap";
import { Target, Info, CheckCircle, PlusCircle } from "react-feather";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Issues,
  Resolved,
  Comment,
  Done,
  All,
  Doing,
  CreateNewProject,
} from "../../constant";
import { useEffect } from "react";
import { db, tutor_db } from "../../data/config";
import moment from "moment";
import { ordinal_suffix_of } from "../../data/ordinalSuffix";

const AllotedStudents = (props) => {
  const [allStudents, setAllStudents] = useState([]);
  const [toolTipVisible, setToolTipVisible] = useState(false);

  const fetchData = async () => {
    const doc = await db
      .collection("profiles")
      .doc(localStorage.getItem("userId"))
      .get();
    const students = doc.data()["allotedStudents"] || [];
    const updatedStudents = [];
    const interns = {};
    await Promise.all(
      students.map(async (d) => {
        const updatedInterns = [];
        const internSnapshot = await db
          .collection("profiles")
          .where("allotedStudents", "array-contains", d)
          .get();
        internSnapshot.forEach((doc) =>
          updatedInterns.push({ id: doc.id, ...doc.data() })
        );
        interns[d] = updatedInterns;
      })
    );
    await Promise.all(
      students.map(async (d) => {
        const student = await tutor_db.collection("students").doc(d).get();
        const studentData = await student.data();
        updatedStudents.push({ id: d, ...studentData, interns: interns[d] });
      })
    );
    console.log(updatedStudents);

    setAllStudents(updatedStudents);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const allStudents2 = useSelector((content) => content.Projectapp.all_Project);

  const toolTipToggle = () => setToolTipVisible(!toolTipVisible);
  return (
    <Fragment>
      <Container fluid={true} hidden={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Row>
                  {allStudents &&
                    allStudents.map((item, i) => (
                      <Col sm="6" className="mt-4" key={i}>
                        <div className="project-box">
                          <span
                            className={`badge ${
                              (item.donationReceived / 18000) * 100 === 100
                                ? "badge-success"
                                : "badge-primary"
                            }`}
                          >
                            Raised: ₹{item.donationReceived}
                          </span>
                          <Row className="mb-2">
                            <div className="col-auto">
                              <Media
                                className="img-100"
                                style={{ borderRadius: 10 }}
                                alt=""
                                src="https://images.unsplash.com/photo-1601275378112-c0d3a890b077"
                              />
                            </div>
                            <Col>
                              <h3 className="mb-1">{item?.name}</h3>
                              <p className="mb-4">{item?.school}</p>
                            </Col>
                          </Row>
                          <p>{item.desc}</p>
                          <Row className="details">
                            <Col xs="6">
                              <span>{"Age"} </span>
                            </Col>
                            <Col
                              xs="6"
                              className={
                                (item.donationReceived / 18000) * 100 === 100
                                  ? "text-success"
                                  : "text-primary"
                              }
                            >
                              {moment().diff(item.dateOfBirth, "years")}
                            </Col>
                            <Col xs="6">
                              <span>{"Standard"}</span>
                            </Col>
                            <Col
                              xs="6"
                              className={
                                (item.donationReceived / 18000) * 100 === 100
                                  ? "text-success"
                                  : "text-primary"
                              }
                            >
                              {ordinal_suffix_of(item.standard)}
                            </Col>
                            <Col xs="6">
                              {" "}
                              <span>{"Medium"}</span>
                            </Col>
                            <Col
                              xs="6"
                              className={
                                (item.donationReceived / 18000) * 100 === 100
                                  ? "text-success"
                                  : "text-primary"
                              }
                              style={{ textTransform: "capitalize" }}
                            >
                              {item.medium}
                            </Col>
                          </Row>
                          {/* <div className="customers">
                            <ul>
                              {item.interns.map((intern) => (
                                <>
                                  <li className="d-inline-block">
                                    {/* <img
                                  className="img-30 rounded-circle"
                                  src={require(`../../assets/images/user/1.jpg`)}
                                  alt=""
                                  
                                  onMouseEnter={{}}
                                /> */}
                          {/* <InitialsAvatar
                                      name={intern.name}
                                      id="TooltipExample"
                                      style={{ width: 25, height: 25 }}
                                    /> */}
                          {/* <Tooltip
                                  isOpen={toolTipVisible}
                                  placement="right"
                                  target="TooltipExample"
                                  toggle={toolTipToggle}
                                >
                                  {intern.name}
                                </Tooltip> */}
                          {/* </li>
                                  <li className="d-inline-block"> */}
                          {/* <img
                                    className="img-30 rounded-circle"
                                    src={require(`../../assets/images/user/1.jpg`)}
                                    alt=""
                                    
                                    onMouseEnter={{}}
                                  /> */}
                          {/* <InitialsAvatar
                                      name={intern.name}
                                      id="TooltipExample"
                                      style={{ width: 25, height: 25 }}
                                    /> */}
                          {/* <Tooltip
                                    isOpen={toolTipVisible}
                                    placement="right"
                                    target="TooltipExample"
                                    toggle={toolTipToggle}
                                  >
                                    {intern.name}
                                  </Tooltip> */}
                          {/* </li>
                                </>
                              ))} */}
                          {/* <li className="d-inline-block">
                                <img
                                  className="img-30 rounded-circle"
                                  src={require(`../../assets/images/${item.customers_img1}`)}
                                  alt=""
                                />
                              </li>
                              <li className="d-inline-block">
                                <img
                                  className="img-30 rounded-circle"
                                  src={require(`../../assets/images/${item.customers_img2}`)}
                                  alt=""
                                />
                              </li>
                              <li className="d-inline-block">
                                <img
                                  className="img-30 rounded-circle"
                                  src={require(`../../assets/images/${item.customers_img3}`)}
                                  alt=""
                                />
                              </li>
                              <li className="d-inline-block ml-2">
                                <p className="f-12">{`+${item.like} More`}</p>
                              </li> */}
                          {/* </ul> */}
                          {/* </div> */}
                          <div className="project-status mt-4">
                            <div className="media mb-0">
                              <p>
                                {Math.floor(
                                  (item.donationReceived / 18000) * 100
                                )}
                                %{" "}
                              </p>
                              <div className="media-body text-right">
                                <span>{Done}</span>
                              </div>
                            </div>
                            {(item.donationReceived / 18000) * 100 === 100 ? (
                              <Progress
                                className="sm-progress-bar"
                                color="success"
                                value={(item.donationReceived / 18000) * 100}
                                style={{ height: "5px" }}
                              />
                            ) : (
                              <Progress
                                className="sm-progress-bar"
                                striped
                                color="primary"
                                value={(item.donationReceived / 18000) * 100}
                                style={{ height: "5px" }}
                              />
                            )}
                          </div>
                        </div>
                      </Col>
                    ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AllotedStudents;
