import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ApexCharts from "react-apexcharts";
import Knob from "knob";
import { areaSpaline, apexMixedCharts } from "./chartsData/apex-charts-data";
import { AlertItemWithIconInverse } from "../ui-kits/alert/alertComponent";
import { AreaSpalineChart } from "../../constant";
import { toast } from "react-toastify";

import GridTable from "@nadavshaar/react-grid-table";
import { firebase_app } from "../../data/config";

import moment from "moment";
import { Check, Edit, Save, X } from "react-feather";
import clsx from "clsx";

const Revenue = (props) => {
  const [selectedBatch, setSelectedBatch] = useState("");
  const [activeRange, setActiveRange] = useState("today");
  const [lastVisible, setLastVisible] = useState(null);

  const controller = new AbortController();

  const onRowsReset = (tableManager) => {
    console.log(tableManager);
    if (tableManager.searchApi.searchText === "") {
      tableManager.rowsApi.setRows([]);
      tableManager.rowsApi.setTotalRows(0);
    } else {
      controller.abort();
    }
  };

  const onRowsRequest = async (requestData, tableManager) => {
    if (requestData.searchText === "") {
      const response = await new Promise((r) => {
        const query = lastVisible
          ? firebase_app
              .firestore()
              .collection("batches")
              .orderBy("timestamp", "desc")
              .limit(20)
              .startAfter(lastVisible)
          : firebase_app
              .firestore()
              .collection("batches")
              .orderBy("timestamp", "desc")
              .limit(20);

        query.get().then((snapshot) => {
          let batches = snapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
            timestamp: doc.data().timestamp.toDate().toDateString(),
          }));

          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);

          // setFundraisers(filteredFundraisers);
          return r({
            items: batches,
            totalItems:
              batches.length < 20
                ? tableManager.rowsApi.rows.length + batches.length
                : lastVisible
                ? tableManager.rowsApi.rows.length + 40
                : 40,
          });
        });
      });

      return {
        rows: response.items,
        totalRows: response.totalItems,
      };
    } else {
      const response = await new Promise((r) => {
        const searchedRows = tableManager.searchApi.searchRows(
          tableManager.rowsApi.rows
        );
        return r({
          items: searchedRows,
          totalItems: searchedRows.length,
        });
      });

      return {
        rows: response.items,
        totalRows: response.totalItems,
      };
    }
  };

  const onRowsRequest2 = async (requestData, tableManager) => {
    if (requestData.from > 0) {
      return true;
    }
    if (requestData.searchText === "") {
      if (selectedBatch !== "") {
        const response = await new Promise((r) => {
          firebase_app
            .firestore()
            .collection("profiles")
            .where("batch", "==", selectedBatch)
            .orderBy("accountCreationTime", "desc")
            .get()
            .then((snapshot) => {
              let fundraisers = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));

              // setFundraisers(filteredFundraisers);
              return r({
                items: fundraisers,
                totalItems: fundraisers.length,
              });
            });
        });

        return {
          rows: response.items,
          totalRows: response.totalItems,
        };
      }
    } else {
      const response = await new Promise((r) => {
        const searchedRows = tableManager.searchApi.searchRows(
          tableManager.rowsApi.rows
        );
        return r({
          items: searchedRows,
          totalItems: searchedRows.length,
        });
      });

      return {
        rows: response.items,
        totalRows: response.totalItems,
      };
    }
  };

  const columns = [
    {
      id: 1,
      field: "name",
      label: "Name",
      sortable: false,
    },
    {
      id: 2,
      field: "timestamp",
      label: "Created On",
      sortable: false,
    },
    {
      id: 3,
      field: "timestamp",
      label: "View Fundraisers",
      cellRenderer: ({ data }) => {
        console.log(data);
        return (
          <>
            <div style={{ margin: "auto" }}>
              <Button
                onClick={() => {
                  toggleModal();
                  setSelectedBatch(data.name);
                }}
                color="primary"
              >
                {"Fundraisers"}
              </Button>
            </div>
          </>
        );
      },
      sortable: false,
    },
  ];

  const columns2 = [
    {
      id: 1,
      field: "name",
      label: "Name",
      sortable: false,
    },
    {
      id: 2,
      field: "acheivedGoal",
      label: "Acheived Goal",
      sortable: false,
    },
    {
      id: 3,
      field: "referenceCode",
      label: "Reference Code",
      sortable: false,
    },
  ];

  const ColumnVisibility = ({ tableManager }) => {
    const { rowSelectionApi } = tableManager;

    const [labelText, setLabelText] = useState("");

    const saveLabel = () => {
      try {
        if (!rowSelectionApi.selectedRowsIds.length > 0) {
          throw new Error("No rows were selected");
        }
        if (labelText === "") {
          throw new Error("Please enter label text");
        }

        let batch = firebase_app.firestore().batch();
        console.log(rowSelectionApi);

        rowSelectionApi.selectedRowsIds.forEach((element) => {
          let docRef = firebase_app
            .firestore()
            .collection("profiles")
            .doc(element);
          batch.update(docRef, { batch: labelText });
        });

        batch.commit().then(() => {
          setTimeout(() => {
            toast.success("Batch is successfully labeled");
          }, 200);
        });
      } catch (e) {
        setTimeout(() => {
          toast.error(e.message);
        }, 200);
      }
    };
    return null;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px 20px",
          background: "#fff",
          width: "50%",
        }}
        className="rgt-header-container"
      >
        <div>
          <label style={{ fontWeight: 500, marginRight: 10 }}>
            Batch label:
          </label>
          <input
            type="text"
            value={labelText}
            onChange={(e) => setLabelText(e.target.value)}
            style={{ width: 300, marginRight: 10 }}
          />
          <Button onClick={saveLabel}>Save</Button>
        </div>
      </div>
    );
  };

  const Search = ({ tableManager }) => {
    const onTimeChange = (time) => {
      setActiveRange(time);
      tableManager.paginationApi.setPage(1);
      tableManager.asyncApi.resetRows();
    };

    const activeClass = (time) => clsx(activeRange === time ? "active" : null);
    return null;
    return (
      <div
        style={{
          padding: "10px 20px",
          background: "#fff",
          width: "60%",
        }}
        className="rgt-header-container"
      >
        <Row className="m-0" style={{ width: "100%" }}>
          <Col xl="8" md="8" sm="8" className="col-12 p-0">
            <div className="inner-top-left">
              <ul className="d-flex list-unstyled">
                <li
                  onClick={() => onTimeChange("today")}
                  className={activeClass("today")}
                >
                  {"Today"}
                </li>
                <li
                  onClick={() => onTimeChange("yesterday")}
                  className={activeClass("yesterday")}
                >
                  {"Yesterday"}
                </li>
                <li
                  onClick={() => onTimeChange("thisWeek")}
                  className={activeClass("thisWeek")}
                >
                  {"This Week"}
                </li>
                <li
                  onClick={() => onTimeChange("lastWeek")}
                  className={activeClass("lastWeek")}
                >
                  {"Last Week"}
                </li>
                <li
                  onClick={() => onTimeChange("allTime")}
                  className={activeClass("allTime")}
                >
                  {"All Time"}
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const Loader = ({ tableManager }) => "Loading";

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <Fragment>
      <Modal isOpen={isModalOpen} toggle={toggleModal} centered size="xl">
        <ModalHeader toggle={toggleModal}>{"Fundraisers"}</ModalHeader>
        <ModalBody>
          <div>
            <GridTable
              columns={columns2}
              onRowsRequest={onRowsRequest2}
              pageSizes={[20]}
              components={{ Search, ColumnVisibility, Loader }}
            />
          </div>
        </ModalBody>
      </Modal>
      <Breadcrumb parent="Dashboard" title="Fundraisers" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col xl="12">
            <Card className="earning-card">
              <CardHeader>
                <h5>{"Batch Labels"} </h5>
              </CardHeader>
              <CardBody>
                <GridTable
                  columns={columns}
                  onRowsRequest={onRowsRequest}
                  pageSizes={[20]}
                  components={{ Search, ColumnVisibility, Loader }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Revenue;
