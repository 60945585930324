import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Input,
  Button,
} from "reactstrap";
import ApexCharts from "react-apexcharts";
import Knob from "knob";
import { areaSpaline, apexMixedCharts } from "./chartsData/apex-charts-data";
import { AlertItemWithIconInverse } from "../ui-kits/alert/alertComponent";
import { AreaSpalineChart } from "../../constant";
import { toast } from "react-toastify";

import GridTable from "@nadavshaar/react-grid-table";
import { firebase_app } from "../../data/config";

import moment from "moment";
import { Check, Edit, Save, X } from "react-feather";
import clsx from "clsx";

const Revenue = (props) => {
  const [fundraisers, setFundraisers] = useState([]);
  const [label, setLabel] = useState("");

  const [activeRange, setActiveRange] = useState("today");
  const [lastVisible, setLastVisible] = useState(null);

  useEffect(() => {
    const currentDate = moment().format("DD-MM-YYYY").split("-");
    const currentDay = currentDate[0];
    const currentMonth = currentDate[1];
    const currentYear = currentDate[2];

    const unsubscribe = firebase_app
      .firestore()
      .collection("profiles")
      .where(
        "accountCreationTime",
        ">=",
        new Date(`${currentYear}-${currentMonth}-${currentDay}`)
      )
      .where(
        "accountCreationTime",
        "<",
        new Date(`${currentYear}-${currentMonth}-${parseInt(currentDay) + 1}`)
      )
      .onSnapshot((snapshot) => {
        const newItem = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setFundraisers(newItem);
      });
    return () => unsubscribe();
  }, []);

  const getRange = () => {
    let fromDate = moment().subtract(1, "days").format("DD-MM-YYYY").split("-");
    let toDate = moment().subtract(1, "days").format("DD-MM-YYYY").split("-");

    if (activeRange === "today") {
      fromDate = moment().format("DD-MM-YYYY").split("-");
      toDate = moment().format("DD-MM-YYYY").split("-");
    }
    if (activeRange === "thisWeek") {
      fromDate = moment().startOf("isoWeek").format("DD-MM-YYYY").split("-");
      toDate = moment().format("DD-MM-YYYY").split("-");
    }
    if (activeRange === "lastWeek") {
      fromDate = moment()
        .subtract(7, "days")
        .startOf("isoWeek")
        .format("DD-MM-YYYY")
        .split("-");
      toDate = moment()
        .subtract(7, "days")
        .endOf("isoWeek")
        .format("DD-MM-YYYY")
        .split("-");
    }

    const range = [];

    range[0] = new Date(`${fromDate[2]}-${fromDate[1]}-${fromDate[0]} 00:00`);
    range[1] = new Date(
      `${toDate[2]}-${toDate[1]}-${parseInt(toDate[0]) + 1} 00:00`
    );
    return range;
  };

  const controller = new AbortController();

  const onRowsReset = (tableManager) => {
    console.log(tableManager);
    if (tableManager.searchApi.searchText === "") {
      tableManager.rowsApi.setRows([]);
      tableManager.rowsApi.setTotalRows(0);
    } else {
      controller.abort();
    }
  };

  const onRowsRequest = async (requestData, tableManager) => {
    if (requestData.from > 0 && activeRange !== "allTime") {
      return true;
    }
    if (requestData.searchText === "") {
      const response = await new Promise((r) => {
        if (activeRange === "allTime") {
          const query = lastVisible
            ? firebase_app
                .firestore()
                .collection("profiles")
                .orderBy("accountCreationTime", "desc")
                .limit(20)
                .startAfter(lastVisible)
            : firebase_app
                .firestore()
                .collection("profiles")
                .orderBy("accountCreationTime", "desc")
                .limit(20);

          query.get().then((snapshot) => {
            let fundraisers = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
              amount: doc.data().acheivedGoal,
            }));

            setLastVisible(snapshot.docs[snapshot.docs.length - 1]);

            // setFundraisers(filteredFundraisers);
            return r({
              items: fundraisers,
              totalItems:
                fundraisers.length < 20
                  ? tableManager.rowsApi.rows.length + fundraisers.length
                  : lastVisible
                  ? tableManager.rowsApi.rows.length + 40
                  : 40,
            });
          });
        } else {
          setLastVisible(null);
          const range = getRange();

          // firebase_app
          //   .firestore()
          //   .collectionGroup("transactions")
          firebase_app
            .firestore()
            .collection("profiles")
            .where("accountCreationTime", ">=", range[0])
            .where("accountCreationTime", "<", range[1])
            .orderBy("accountCreationTime", "desc")
            .get()
            .then(async (snapshot) => {
              let fundraisers = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));

              // setFundraisers(filteredFundraisers);
              return r({
                items: fundraisers,
                totalItems: fundraisers.length,
              });
            });
        }
      });

      return {
        rows: response.items,
        totalRows: response.totalItems,
      };
    } else {
      const response = await new Promise((r) => {
        const searchedRows = tableManager.searchApi.searchRows(
          tableManager.rowsApi.rows
        );
        return r({
          items: searchedRows,
          totalItems: searchedRows.length,
        });
      });

      return {
        rows: response.items,
        totalRows: response.totalItems,
      };
    }
  };

  const columns = [
    { id: "checkbox" },
    {
      id: 1,
      field: "email",
      label: "Email",
      sortable: false,
    },
    {
      id: 2,
      field: "name",
      label: "Name",
      sortable: false,
    },
    {
      id: 3,
      field: "batch",
      label: "Batch",
      sortable: false,
    },
  ];

  const ColumnVisibility = ({ tableManager }) => {
    const { rowSelectionApi } = tableManager;

    const [labelText, setLabelText] = useState("");

    const saveLabel = () => {
      try {
        if (!rowSelectionApi.selectedRowsIds.length > 0) {
          throw new Error("No rows were selected");
        }
        if (labelText === "") {
          throw new Error("Please enter label text");
        }

        let batch = firebase_app.firestore().batch();

        rowSelectionApi.selectedRowsIds.forEach((element) => {
          let docRef = firebase_app
            .firestore()
            .collection("profiles")
            .doc(element);
          batch.update(docRef, { batch: labelText });
        });
        firebase_app
          .firestore()
          .collection("batches")
          .where("name", "==", labelText)
          .get()
          .then((docs) => {
            if (docs.empty) {
              firebase_app
                .firestore()
                .collection("batches")
                .add({ name: labelText, timestamp: new Date() });
            }
          });

        batch.commit().then(() => {
          setTimeout(() => {
            toast.success("Batch is successfully labeled");
          }, 200);
        });
      } catch (e) {
        setTimeout(() => {
          toast.error(e.message);
        }, 200);
      }
    };

    const onTimeChange = (time) => {
      setActiveRange(time);
      tableManager.paginationApi.setPage(1);
      tableManager.asyncApi.resetRows();
    };

    const activeClass = (time) => clsx(activeRange === time ? "active" : null);

    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 20px",
            background: "#fff",
            width: "200px",
          }}
          className="rgt-header-container"
        >
          <div>
            <label style={{ fontWeight: 500, marginRight: 10 }}>
              Batch label:
            </label>
            <input
              type="text"
              value={labelText}
              onChange={(e) => setLabelText(e.target.value)}
              style={{ width: 150, marginRight: 10 }}
            />
            <Button onClick={saveLabel}>Save</Button>
          </div>
        </div>
        <div
          style={{
            padding: "10px 20px",
            background: "#fff",
            width: "500px",
          }}
          className="rgt-header-container"
        >
          <Row className="m-0" style={{ width: "100%" }}>
            <Col xl="8" md="8" sm="8" className="col-12 p-0">
              <div className="inner-top-left">
                <ul className="d-flex list-unstyled">
                  <li
                    onClick={() => onTimeChange("today")}
                    className={activeClass("today")}
                  >
                    {"Today"}
                  </li>
                  <li
                    onClick={() => onTimeChange("yesterday")}
                    className={activeClass("yesterday")}
                  >
                    {"Yesterday"}
                  </li>
                  <li
                    onClick={() => onTimeChange("thisWeek")}
                    className={activeClass("thisWeek")}
                  >
                    {"This Week"}
                  </li>
                  <li
                    onClick={() => onTimeChange("lastWeek")}
                    className={activeClass("lastWeek")}
                  >
                    {"Last Week"}
                  </li>
                  <li
                    onClick={() => onTimeChange("allTime")}
                    className={activeClass("allTime")}
                  >
                    {"All Time"}
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const Search = ({ tableManager }) => {
    const onTimeChange = (time) => {
      setActiveRange(time);
      tableManager.paginationApi.setPage(1);
      tableManager.asyncApi.resetRows();
    };

    const activeClass = (time) => clsx(activeRange === time ? "active" : null);
    return (
      <div
        style={{
          padding: "10px 20px",
          background: "#fff",
          width: "60%",
        }}
        className="rgt-header-container"
      >
        <Row className="m-0" style={{ width: "100%" }}>
          <Col xl="8" md="8" sm="8" className="col-12 p-0">
            <div className="inner-top-left">
              <ul className="d-flex list-unstyled">
                <li
                  onClick={() => onTimeChange("today")}
                  className={activeClass("today")}
                >
                  {"Today"}
                </li>
                <li
                  onClick={() => onTimeChange("yesterday")}
                  className={activeClass("yesterday")}
                >
                  {"Yesterday"}
                </li>
                <li
                  onClick={() => onTimeChange("thisWeek")}
                  className={activeClass("thisWeek")}
                >
                  {"This Week"}
                </li>
                <li
                  onClick={() => onTimeChange("lastWeek")}
                  className={activeClass("lastWeek")}
                >
                  {"Last Week"}
                </li>
                <li
                  onClick={() => onTimeChange("allTime")}
                  className={activeClass("allTime")}
                >
                  {"All Time"}
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const Loader = ({ tableManager }) => "Loading";

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Fundraisers" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col xl="12">
            <Card className="earning-card">
              <CardHeader>
                <h5>{"Batch Labels"} </h5>
              </CardHeader>
              <CardBody>
                <GridTable
                  columns={columns}
                  onRowsRequest={onRowsRequest}
                  onRowsReset={onRowsReset}
                  pageSizes={[20]}
                  components={{ ColumnVisibility, Loader }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Revenue;
